import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';


@Component({
  selector: 'app-data-grid-sidebar',
  templateUrl: './data-grid-sidebar.component.html',
  styleUrls: ['./data-grid-sidebar.component.scss']
})

export class DataGridSidebarComponent implements OnInit {

  /** Current data input stream */
  @Input() public data: {};

  /** Current view input stream */
  @Input() public currentView: string;
  @Output() public viewChanged: EventEmitter<string> = new EventEmitter();

  /** Filter input that is defined by user */
  @Input() public filterInput: string;
  @Output() public filterInputChange = new EventEmitter();

  /** User defined variable to show or hide add new button on sidebar */
  @Input() public addButton: boolean;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() public onAddButton: EventEmitter<boolean> = new EventEmitter();

  /** Helper variable for showing current active view on sidebar (list or card) */
  public active: string;

  constructor(
  ) { }

  ngOnInit() {
  }

  /**
   * Method for changing view on user screen
   * @param {string} param current view
   */
  public changeView(param: string): void {
    this.currentView = param;
    this.viewChanged.emit(this.currentView);
  }

  /** Method for showing/hiding add button to user sidebar */
  public onAdd(): void {
    this.onAddButton.emit(true);
  }

  /** Method for highlight current active view (list or card view ) */
  public currentActive(e): void {
    this.active = e.target.innerText;
  }

  /** Method that emits current user inputed search value */
  public filterItem(value): void {
    this.filterInputChange.emit(value);
  }

}
