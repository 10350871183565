import { Auth } from './../../../../core/auth/auth.model';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ErrorService } from './../../../../core/error.service';
import { ToastService } from './../../../../shared/toast/toast.service';
import { Component, OnInit } from '@angular/core';
import { EmployeesService } from '../../services/employees.service';

@Component({
  selector: 'app-employee-edit',
  templateUrl: './employee-edit.component.html',
  styleUrls: ['./employee-edit.component.scss']
})
export class EmployeeEditComponent implements OnInit {
  /** Edit form */
  public editForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl(''),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    title: new FormControl(''),
    workPlace: new FormControl(''),
    email: new FormControl('', [Validators.required])
  });

  /** Employee object */
  private employee: Auth;

  /** Flag if user is new */
  public isNew = false;

  public oldPassword: string;
  public newPassword: string;
  public newPasswordRetype: string;

  constructor(
    private _employee: EmployeesService,
    private _toast: ToastService,
    private _error: ErrorService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe(params => {
      const id = params['id'];

      if (+id) {
        this.getUserById(id);
      } else {
        this.isNew = true;
      }
    });
  }

  ngOnInit() {}

  private getUserById(id): void {
    this._employee.getUserById(id).subscribe(
      data => {
        this.editForm.patchValue(data);
        this.employee = data;
      },
      error => {
        this._error.error(error);
      }
    );
  }

  /**
   * Edit existing user
   */
  private update(): void {
    this._employee.editUser(this.editForm.value).subscribe(
      data => {
        this._toast.success('Success', 'User successfully edited', true, 1500);
      },
      error => {
        this._error.error(error);
      }
    );
  }

  /** Register new user */
  private register(): void {
    this._employee.registerUser(this.editForm.value).subscribe(
      data => {
        this._toast.success(
          'Success',
          'User successfully registered',
          true,
          1500
        );
      },
      error => {
        this._error.error(error);
      }
    );
  }

  /** Change password */
  public passwordChange(): void {
    if (this.newPassword !== this.newPasswordRetype) {
      this._toast.error('Error', 'New passwords do not match');
    } else {
      const data = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
        userId: this.employee.userId
      };

      this.changePassword(data);
    }
  }

  /** Method that handles password change. */
  private changePassword(d): void {
    this._employee.changePassword(d).subscribe(
      data => {
        this._toast.success(
          'Success',
          'Successfully changed password',
          true,
          1500
        );
        this.oldPassword = '';
        this.newPassword = '';
        this.newPasswordRetype = '';
      },
      error => {
        this._error.error(error);
      }
    );
  }

  /** Submit data */
  public submit(data): void {
    if (this.isNew) {
      this.register();
    } else {
      this.update();
    }
  }

  /** Cancel to employee list */
  public cancel(): void {
    this.router.navigate(['/employees']);
  }

}
