import { Injectable } from '@angular/core';

@Injectable()
export class SidebarService {
  /** Flag that marks whether the sidebar is shown or not. */
  sidebarHidden: boolean = false;


  /** Flag that marks whether the sidebar logo image is hidden or not. */
  public imageHidden: boolean;

  constructor() {
    localStorage.getItem('sidebar_hidden') ? this.sidebarHidden = JSON.parse(localStorage.getItem('sidebar_hidden')) : this.sidebarHidden = false;
  }

  /** Method that toggles sidebar visible/hidden. */
  toggleSidebar(): boolean {
    this.sidebarHidden = !this.sidebarHidden;
    localStorage.setItem('sidebar_hidden', JSON.stringify(this.sidebarHidden));
    return this.sidebarHidden;
  }
}
