import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';
import { Column } from './column.model';
import { DataGridPaginationComponent } from './data-grid-pagination/data-grid-pagination.component';

@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss']
})
export class DataGridComponent implements OnInit {
  /** Current data input stream */
  public currentData: any;

  /** Copy of current data */
  public copyData: any;

  /** Search input value from user input (sidebar) */
  public searchInput: string;

  /** dataGrid pagination child element */
  @ViewChild('pagination', { static: false })
  public dataPagination: DataGridPaginationComponent;

  /** variable that shows current view on user screen */
  @Input() public view = 'listView';
  @Output() public viewChanged: EventEmitter<string> = new EventEmitter();

  /** User defined columns on list and card view */
  @Input() public columns: Column[];

  /** User defined max height of current list or card view container) */
  @Input() public maxHeight: number;

  /** User defined number of elements showed on one page of pagination */
  @Input() public pageSize: number = 8;

  /** Current page of the data grid. */
  public currentPage: number;

  /** Customize edit button for each row */
  @Input() public customEdit: boolean = false;

  /** is data grid row editable */
  @Input() public editables: boolean[] = [];

  /** Setter for current data input */
  @Input() set data(data: any) {
    this.copyData = data;
    this.currentData = data;
  }

  /** User defined variable to show or hide add new button on sidebar */
  @Input() public addButton;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() public onAddButton: EventEmitter<boolean> = new EventEmitter();

  /** User defined variable to show or hide edit button */
  @Input() public editButton;

  /** User defined variable to show or hide delete button */
  @Input() public deleteButton;

  /** User defined variable to show or hide statistics button */
  @Input() public statButton;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() public onEditButton: EventEmitter<Object> = new EventEmitter();

  // tslint:disable-next-line:no-output-on-prefix
  @Output() public onDeleteButton: EventEmitter<Object> = new EventEmitter();

  /** Field for the card title. */
  @Input() public nameField: any;

  /** Helper variable for storing temp data */
  public temp: any = null;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() public onClick: EventEmitter<Object> = new EventEmitter();

  // tslint:disable-next-line:no-output-on-prefix
  @Output() public onStatButton: EventEmitter<Object> = new EventEmitter();

  /** Height of the data grid component. */
  public height: number;

  /** User defined row title. */
  @Input() public rowTitle: string;

  constructor() {}

  ngOnInit() {
    this.calculateGridHeight(window.innerHeight);
  }
  /**
   * Method for changing view on user screen
   * @param {string} param current view
   */
  public viewChange(param: string): void {
    this.view = param;
    this.viewChanged.emit(param);
  }

  /**
   * Method for showing/hiding add button to user sidebar
   * @param {boolean} value true or false
   */
  public onAdd(value: boolean): void {
    this.onAddButton.emit(value);
  }

  /**
   * Method for invoking and passing current object that is clicked for edit
   * @param {Object} value passed object
   */
  public onEdit(value: Object): void {
    this.onEditButton.emit(value);
  }

  /**
   * Method for invoking and passing current object that is clicked for delete
   * @param {Object} value passed object
   */
  public onDelete(value: Object): void {
    this.onDeleteButton.emit(value);
  }

  /**
   * Method for invoking and passing current object that is clicked for delete
   * @param {Object} value passed object
   */
  public onStat(value: Object): void {
    this.onStatButton.emit(value);
  }

  /**
   * Method for invoking and passing current object that is clicked.
   * @param value passed object
   */
  public clickOnItem(value: Object): void {
    this.onClick.emit(value);
  }

  /**
   * Method for storing filtered data based on search input
   * @param {string} item searched input value
   */
  public searchItem(item: string): void {
    if (this.temp === null) {
      this.temp = this.currentData;
    }

    if (item !== '') {
      this.currentData = this.filterItems(item, this.copyData);
    } else {
      this.currentData = this.temp;
      this.temp = null;
    }
  }

  /**
   * Method for filtering data based on a search input
   * @param {string} searchItem search item value
   * @param {*} data current data that is passed
   * @returns {*}
   */
  public filterItems(searchItem: string, data: any): any {
    return data.filter(
      item =>
        JSON.stringify(item)
          .toLowerCase()
          .indexOf(searchItem.toLowerCase()) > -1
    );
  }

  /**
   * Method for setting pagination based on paginated data
   * @param {number} page current passed page
   */
  public setPage(page: number): void {
    this.currentPage = page;
    const totalItems = this.copyData.length;
    const pageSize = this.pageSize;
    this.dataPagination.pager = this.getPager(totalItems, page, pageSize);
    this.currentData = this.copyData.slice(
      this.dataPagination.pager.startIndex,
      this.dataPagination.pager.endIndex + 1
    );
  }

  /**
   * Method for calculating pagination logic
   * @param {number} totalItems total items in passed data
   * @param {number} [currentPage=1] current page
   * @param {number} [pageSize=4] size that defines how many items are in one page
   * @returns {object}
   */
  public getPager(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 4
  ): object {
    /** calculate total pages */
    const totalPages = Math.ceil(totalItems / pageSize);

    /** ensure current page isn't out of range */
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;

    if (totalPages <= 10) {
      /** less than 10 total pages so show all */
      startPage = 1;
      endPage = totalPages;
    } else {
      /** more than 10 total pages so calculate start and end pages */
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    /** calculate start and end item indexes */
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    /** create an array of pages to ng-repeat in the pager control */
    const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      i => startPage + i
    );

    /** return object with all pager properties required by the view */
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  /** Method that is triggered on window resize, used for calculating height of the data grid. */
  public onResize(): void {
    this.calculateGridHeight(window.innerHeight);
  }

  /** Method that calculates the data grid height and number of projects displayed on one page of the grid based on the current window height.
   * @param windowHeight Height of the browser window.
   */
  public calculateGridHeight(windowHeight: number): void {
    if (windowHeight + 1 >= 1116) {
      this.pageSize = 16;
      setTimeout(() => {
        this.dataPagination.setPage(this.currentPage);
      });
    } else if (windowHeight + 1 < 1116 && windowHeight + 1 >= 1064) {
      this.pageSize = 15;
      setTimeout(() => {
        this.dataPagination.setPage(this.currentPage);
      });
    } else if (windowHeight + 1 < 1064 && windowHeight + 1 >= 1012) {
      this.pageSize = 14;
      setTimeout(() => {
        this.dataPagination.setPage(this.currentPage);
      });
    } else if (windowHeight + 1 < 1012 && windowHeight + 1 >= 960) {
      this.pageSize = 13;
      setTimeout(() => {
        this.dataPagination.setPage(this.currentPage);
      });
    } else if (windowHeight + 1 < 960 && windowHeight + 1 >= 908) {
      this.pageSize = 12;
      setTimeout(() => {
        this.dataPagination.setPage(this.currentPage);
      });
    } else if (windowHeight + 1 < 908 && windowHeight + 1 >= 856) {
      this.pageSize = 11;
      setTimeout(() => {
        this.dataPagination.setPage(this.currentPage);
      });
    } else if (windowHeight + 1 < 856 && windowHeight + 1 >= 804) {
      this.pageSize = 10;
      setTimeout(() => {
        this.dataPagination.setPage(this.currentPage);
      });
    } else if (windowHeight + 1 < 804 && windowHeight + 1 >= 752) {
      this.pageSize = 9;
      setTimeout(() => {
        this.dataPagination.setPage(this.currentPage);
      });
    } else if (windowHeight + 1 < 752 && windowHeight + 1 >= 700) {
      this.pageSize = 8;
      setTimeout(() => {
        this.dataPagination.setPage(this.currentPage);
      });
    } else if (windowHeight + 1 < 700 && windowHeight + 1 >= 648) {
      this.pageSize = 7;
      setTimeout(() => {
        this.dataPagination.setPage(this.currentPage);
      });
    } else if (windowHeight + 1 < 648 && windowHeight + 1 >= 596) {
      this.pageSize = 6;
      setTimeout(() => {
        this.dataPagination.setPage(this.currentPage);
      });
    } else {
      this.pageSize = 5;
      setTimeout(() => {
        this.dataPagination.setPage(this.currentPage);
      });
    }
  }
}
