import { BaseResolver } from './base.resolver';
import { DashboardComponent } from './../../modules/dashboard/components/dashboard/dashboard.component';
import { SettingsComponent } from './../../modules/profile/components/settings/settings.component';
import { ProfileComponent } from './../../modules/profile/components/profile/profile.component';
import { AdminGuard } from './../auth/admin.guard';
import { AuthGuard } from './../auth/auth.guard';
import { MainComponent } from './main/main.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    resolve: {
      data: BaseResolver
    },
    children: [
      {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'calendar',
        canActivate: [AuthGuard],
        loadChildren: () => import('app/modules/calendar/calendar.module').then(m => m.CalendarModule)
      },
      {
        path: 'projects',
        canActivate: [AuthGuard],
        loadChildren: () => import('app/modules/projects/projects.module').then(m => m.ProjectsModule)
      },
      {
        path: 'vacation',
        canActivate: [AuthGuard],
        loadChildren: () => import('app/modules/vacation/vacation.module').then(m => m.VacationModule)
      },
      {
        path: 'employees',
        canActivate: [AuthGuard, AdminGuard],
        loadChildren: () => import('app/modules/employees/employees.module').then(m => m.EmployeesModule)
      },
      {
        path: 'clients',
        canActivate: [AuthGuard, AdminGuard],
        loadChildren: () => import('app/modules/clients/clients.module').then(m => m.ClientsModule)
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        component: ProfileComponent
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        component: SettingsComponent
      },
      {
        path: 'messages',
        canActivate: [AuthGuard, AdminGuard],
        loadChildren: () => import('app/modules/messages/messages.module').then(m => m.MessagesModule)
      },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
  ]
})
export class LayoutRoutingModule { }
