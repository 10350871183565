import { AuthService } from './../../auth/auth.service';
import { MenuItem } from './menuitem.model';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { SidebarService } from '../sidebar.service';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {
  /** Array with the list of all menu items  */
  public menuItems: MenuItem[] = [];

  /** Sidebar element */
  @ViewChild('sidebar', { static: true }) public sidebar: ElementRef;

  /** Sidebar item element */
  @ViewChild('sidebaritem', { static: false }) public sidebarItem: ElementRef;

  /** Sidebar image element */
  @ViewChild('sidebarimage', { static: true }) public sidebarImage: ElementRef;

  /** Sidebar image height */
  private imageHeight: number;

  constructor(
    public _sidebar: SidebarService,
    public _auth: AuthService,
    public router: Router
  ) {}

  ngOnInit() {
    /** Pushing all the sidebar menu items into the menuItems array. */
    let item = new MenuItem('fa fa-clipboard', 'Dashboard', '');
    this.menuItems.push(item);
    item = new MenuItem('fa fa-briefcase', 'Projects', '/projects');
    this.menuItems.push(item);
    item = new MenuItem('fa fa-calendar', 'Calendar', '/calendar');
    this.menuItems.push(item);
    item = new MenuItem('fa fa-bed', 'Vacations', '/vacation');
    this.menuItems.push(item);
    if (this._auth.user.systemRole.id === 2) {
      item = new MenuItem('fa fa-users', 'Employees', '/employees');
      this.menuItems.push(item);
      item = new MenuItem('fa fa-address-book', 'Clients', '/clients');
      this.menuItems.push(item);
      item = new MenuItem('fa fa-envelope', 'Messages', '/messages');
      this.menuItems.push(item);
    }

    this.activateSidebarItem();

    this.router.events.subscribe(data => {
      if (event => event instanceof NavigationStart) {
        this.activateSidebarItem();
      }
    });
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    this.imageHeight = this.sidebarImage.nativeElement.offsetHeight + 100;
    setTimeout(() => {
      this.onResize(null);
    });
  }

  /** Method that changes color of a sidebar element if a corresponding component is active. */
  public activateSidebarItem(): void {
    this.menuItems.forEach(el => {
      el.active = false;
      if (this.router.url === el.url) {
        el.active = true;
      } else if (this.router.url === '/') {
        this.menuItems[0].active = true;
      } else {
        el.active = false;
      }
    });
  }

  public onResize(event) {
    if (
      this.menuItems.length * this.sidebarItem.nativeElement.offsetHeight +
        this.imageHeight +
        10 >
      this.sidebar.nativeElement.offsetHeight
    ) {
      this._sidebar.imageHidden = true;
    } else {
      this._sidebar.imageHidden = false;
    }
  }
}
