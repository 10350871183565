import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastService } from '../../../shared/toast/toast.service';
import { Column } from '../../../shared/data-grid/column.model';
import { DropdownComponent } from '../../../shared/dropdown/dropdown.component';
import { AutocompleteComponent } from '../../../shared/autocomplete/autocomplete.component';


class Item {
  name: string;
  city: string;
  age: number;

  constructor(name?: string, city?: string, age?: number) {
    this.name = name;
    this.city = city;
    this.age = age;
  }
}

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})

export class TestComponent implements OnInit {
  data: Item[] = [];

  dataGrid: any = [
    { id: 1, cardName: 'Title 1', firstName: 'Luka', lastName: 'Lukito', age: 19, location: 'Osijek' },
    { id: 2, cardName: 'Title 2', firstName: 'Miro', lastName: 'Meredol', age: 27, location: 'Osijek' },
    { id: 3, cardName: 'Title 3', firstName: 'Pero', lastName: 'Prangija', age: 27, location: 'Osijek' },
    { id: 4, cardName: 'Title 4', firstName: 'Ferdo', lastName: 'Ferdić', age: 23, location: 'Osijek'},
    { id: 5, cardName: 'Title 5', firstName: 'Juro', lastName: 'Jurinović', age: 23, location: 'Osijek' },
    { id: 6, cardName: 'Title 6', firstName: 'Štef', lastName: 'Kutina', age: 18, location: 'Osijek'},
    { id: 7, cardName: 'Title 7', firstName: 'Lule', lastName: 'Levat', age: 23, location: 'Osijek' },
    { id: 8, cardName: 'Title 8', firstName: 'Domagoj', lastName: 'Vida', age: 35, location: 'Osijek' },
    { id: 9, cardName: 'Title 9', firstName: 'Ante', lastName: 'Kumašin', age: 28, location: 'Osijek' },
    { id: 10, cardName: 'Title 10', firstName: 'Milan', lastName: 'Badelj', age: 34, location: 'Osijek' },
    { id: 11, cardName: 'Title 11', firstName: 'Mile', lastName: 'Kitić', age: 26, location: 'Osijek' },
    { id: 12, cardName: 'Title 12', firstName: 'Đani', lastName: 'Đani', age: 44, location: 'Osijek' },
    { id: 12, cardName: 'Title 12', firstName: 'Đani', lastName: 'Đani', age: 44, location: 'Osijek' },
    { id: 12, cardName: 'Title 12', firstName: 'Đani', lastName: 'Đani', age: 44, location: 'Osijek' },
    { id: 12, cardName: 'Title 12', firstName: 'Đani', lastName: 'Đani', age: 44, location: 'Osijek' },
    { id: 12, cardName: 'Title 12', firstName: 'Đani', lastName: 'Đani', age: 44, location: 'Osijek' },
    { id: 12, cardName: 'Title 12', firstName: 'Đani', lastName: 'Đani', age: 44, location: 'Osijek' },
    { id: 12, cardName: 'Title 12', firstName: 'Đani', lastName: 'Đani', age: 44, location: 'Osijek' },
    { id: 12, cardName: 'Title 12', firstName: 'Đani', lastName: 'Đani', age: 44, location: 'Osijek' },
    { id: 12, cardName: 'Title 12', firstName: 'Đani', lastName: 'Đani', age: 44, location: 'Osijek' },
    { id: 12, cardName: 'Title 12', firstName: 'Đani', lastName: 'Đani', age: 44, location: 'Osijek' },
    { id: 12, cardName: 'Title 12', firstName: 'Đani', lastName: 'Đani', age: 44, location: 'Osijek' },
    { id: 12, cardName: 'Title 12', firstName: 'Đani', lastName: 'Đani', age: 44, location: 'Osijek' },
    { id: 12, cardName: 'Title 12', firstName: 'Đani', lastName: 'Đani', age: 44, location: 'Osijek' },
    { id: 12, cardName: 'Title 12', firstName: 'Đani', lastName: 'Đani', age: 44, location: 'Osijek' },
    { id: 12, cardName: 'Title 12', firstName: 'Đani', lastName: 'Đani', age: 44, location: 'Osijek' },
    { id: 12, cardName: 'Title 12', firstName: 'Đani', lastName: 'Đani', age: 44, location: 'Osijek' },
  ];

  dataGridColumns: Column[] = [
    new Column('First Name', 'firstName'),
    new Column('Last name', 'lastName'),
    new Column('Age', 'age'),
    new Column('Location', 'location'),
  ];

  @ViewChild(DropdownComponent, {static: true})
  private child: DropdownComponent;

  @ViewChild(AutocompleteComponent, {static: true})
  private childAuto: AutocompleteComponent;

  constructor(
    public _toast: ToastService,
  ) {
    let item = new Item('John', 'New York', 31);
    this.data.push(item);
    item = new Item('Eva', 'Los Angeles', 24);
    this.data.push(item);
    item = new Item('Lucas', 'Seattle', 28);
    this.data.push(item);
    item = new Item('Leah', 'Miami', 26);
    this.data.push(item);
    item = new Item('Michael', 'Washington', 42);
    this.data.push(item);
  }

  ngOnInit() {
  }
  selectDrop(): void {
    this.child.select(this.data[2]);
  }

  selectAuto(): void {
    this.childAuto.select(this.data[4]);
  }

  confirm(confirmed: boolean) {
    if (confirmed === true) {
    }
  }


}
