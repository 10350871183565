import { AuthService } from './../../../../core/auth/auth.service';
import { ErrorService } from './../../../../core/error.service';
import { ToastService } from './../../../../shared/toast/toast.service';
import { EmployeesService } from './../../../employees/services/employees.service';
import { InputComponent } from './../../../../shared/input/input.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-profile-edit-user',
  templateUrl: './profile-edit-user.component.html',
  styleUrls: ['./profile-edit-user.component.scss']
})
export class ProfileEditUserComponent implements OnInit {
  /** Form for user editing. */
  public editUserForm = new FormGroup({
    userId: new FormControl(''),
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    title: new FormControl(''),
    workPlace: new FormControl(''),
    email: new FormControl('')
  });

  /** Email input component. */
  @ViewChild('email', { static: true }) private emailInput: InputComponent;
  /** First name input component. */
  @ViewChild('firstname', { static: true })
  private firstNameInput: InputComponent;
  /** Last name input component. */
  @ViewChild('lastname', { static: true })
  private lastNameInput: InputComponent;
  /** Workplace input component. */
  @ViewChild('workplace', { static: true })
  private workplaceInput: InputComponent;
  /** Title input component. */
  @ViewChild('title', { static: true }) private titleInput: InputComponent;

  constructor(
    private _employees: EmployeesService,
    private _toast: ToastService,
    private _error: ErrorService,
    private _auth: AuthService
  ) {}

  ngOnInit() {
    this.emailInput.input = this._auth.user.email;
    this.firstNameInput.input = this._auth.user.firstName;
    this.lastNameInput.input = this._auth.user.lastName;
    this.workplaceInput.input = this._auth.user.workPlace;
    this.titleInput.input = this._auth.user.title;
  }

  /** Method that edits the user logged in. */
  public editUser(): void {
    this.editUserForm.setValue({
      userId: this._auth.user.userId,
      email: this.emailInput.input,
      firstName: this.firstNameInput.input,
      lastName: this.lastNameInput.input,
      title: this.titleInput.input,
      workPlace: this.workplaceInput.input
    });
    this._employees.editUser(this.editUserForm.value).subscribe(
      data => {
        this._toast.success('Success', 'Successfully edited info', true, 1500);
      },
      error => {
        this._error.error(error);
      }
    );
  }
}
