import { Column } from '../column.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-data-grid-card-view',
  templateUrl: './data-grid-card-view.component.html',
  styleUrls: ['./data-grid-card-view.component.scss']
})
export class DataGridCardViewComponent implements OnInit {

  /** Defined input data */
  @Input() public data: any;

  /** Defined input columns */
  @Input() public columns: Column[];

  /** Defined current view showed to user */
  @Input() public currentView: string;
  @Output() public viewChanged: EventEmitter<string> = new EventEmitter();

  // tslint:disable-next-line:no-output-on-prefix
  @Output() public onEditButton: EventEmitter<Object> = new EventEmitter();

  // tslint:disable-next-line:no-output-on-prefix
  @Output() public onDeleteButton: EventEmitter<Object> = new EventEmitter();

    // tslint:disable-next-line:no-output-on-prefix
  @Output() public onClick: EventEmitter<Object> = new EventEmitter();

  /** Field for the card title. */
  @Input() public nameField: any;

  /** User defined variable to show or hide edit button */
  @Input() public editButton;

  /** User defined variable to show or hide delete button */
  @Input() public deleteButton;

  /** User defined row title. */
  @Input() public rowTitle;

  /** User defined variable to show or hide statistics button */
  @Input() public statButton = false;

  /** Customize edit button for each row */
  @Input() public customEdit: boolean = false;

  /** is data grid row editable */
  @Input() public editables: boolean[] = [];

  // tslint:disable-next-line:no-output-on-prefix
  @Output() public onStatButton: EventEmitter<Object> = new EventEmitter();

  constructor(

  ) { }

  ngOnInit() {
  }

  /**
  * Method for invoking and passing current object that is clicked for edit
  * @param item passed object
  * @param event Click event.
  */
  public editItem(item, event): void {
    this.onEditButton.emit(item);
    event.stopPropagation();
  }

    /**
  * Method for invoking and passing current object that is clicked for delete
  * @param item passed object
  * @param event Click event.
  */
  public deleteItem(item, event): void {
    this.onDeleteButton.emit(item);
    event.stopPropagation();
  }

  /** Method for invoking and passing current object that is clicked.
   * @param item passed object
   */
  public clickOnItem(item: Object): void {
    this.onClick.emit(item);
  }


  /** Method for opening statistics */
  public openStats(item, event): void {
    this.onStatButton.emit(item);
    event.stopPropagation();
  }

}
