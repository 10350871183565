import { Auth } from './../../../core/auth/auth.model';
import { Project } from './../models/project.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjectStatistics } from '../models/project-statistics.model';
import { ProjectStatisticsByTasks } from '../models/project-statistics-by-tasks.model';
import { PotentialUserLeader } from '../models/potential-user-leader.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  /** Backend URL. */
  private url: string = environment.url + 'projects/';

  /** Currently selected project. */
  public selectedProject: Project;

  constructor(private http: HttpClient) {}

  /** Method that returns an array that contains all the projects in the database. */
  public getProjects(): Observable<Project[]> {
    return this.http.get<Project[]>(this.url).pipe(
      map(data => {
        const projects = [];
        data.forEach(project => {
          projects.push(Object.assign(new Project(), project));
        });
        return projects;
      })
    );
  }

  /**
   * Method that returns a project based on its ID.
   * @param projectId ID of a project.
   */
  public getProjectById(projectId: number): Observable<Project> {
    return this.http.get<Project>(this.url + projectId).pipe(
      map(data => {
        const obj = new Project();
        Object.assign(obj, data);
        return obj;
      })
    );
  }

  /** Method that handles inserting a new project to the database.
   * @param formData New project parameters.
   */
  public insertProject(formData: Object): Observable<Project> {
    return this.http.post<Project>(this.url, formData).pipe(
      map(data => {
        const obj = new Project();
        Object.assign(obj, data);
        return obj;
      })
    );
  }

  /** Method that handles updating an existing project.
   * @param formData New parameters of the project.
   */
  public updateProject(formData: Object): Observable<Project> {
    return this.http.put<Project>(this.url, formData).pipe(
      map(data => {
        const obj = new Project();
        Object.assign(obj, data);
        return obj;
      })
    );
  }

  /**
   * Method that handles adding users to projects as a project leader or a regular user.
   * @param roleId 3 for project leader, 4 for regular user.
   * @param projectId ID of the project which is the user added to.
   * @param userId ID of the user to be added.
   */
  public addUserToProject(
    roleId: number,
    projectId: number,
    userId: number
  ): Observable<boolean> {
    let params = new HttpParams();
    params = params.set('roleId', String(roleId));
    params = params.set('userId', String(userId));
    params = params.set('projectId', String(projectId));
    return this.http
      .post<boolean>(
        this.url +
          'users/add?roleId=' +
          roleId +
          '&projectId=' +
          projectId +
          '&userId=' +
          userId,
        null
      )
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  /** Method that gets all the potential project leaders. */
  public getPotentialProjectLeaders(): Observable<PotentialUserLeader[]> {
    return this.http.get<PotentialUserLeader[]>(this.url + 'leaders').pipe(
      map(data => {
        const users = [];
        data.forEach(user => {
          users.push(Object.assign(new PotentialUserLeader(), user));
        });
        return users;
      })
    );
  }

  /** Method that gets all the potential project users. */
  public getPotentialProjectUsers(): Observable<PotentialUserLeader[]> {
    return this.http.get<PotentialUserLeader[]>(this.url + 'users').pipe(
      map(data => {
        const users = [];
        data.forEach(user => {
          users.push(Object.assign(new PotentialUserLeader(), user));
        });
        return users;
      })
    );
  }

  /**
   * Method that adds the selected user as a potential project leader or user.
   * @param userId ID of the selected user.
   * @param role New role (Project leader or Project user).
   */
  public insertUserLeader(
    userId: number,
    role: string
  ): Observable<PotentialUserLeader> {
    let params = new HttpParams();
    params = params.set('userId', String(userId));
    params = params.set('projectRole', role);
    return this.http.post<PotentialUserLeader>(this.url + 'roles', params).pipe(
      map(data => {
        const obj = new PotentialUserLeader();
        Object.assign(obj, data);
        return obj;
      })
    );
  }

  /**
   * Method that deletes a user from the potential leaders table.
   * @param userId Selected user ID.
   */
  public deletePotentialLeader(userId: number): Observable<boolean> {
    return this.http.delete<boolean>(
      this.url + 'leaders/' + userId + '/delete'
    ).pipe(
      map(data => {
        return data;
      })
    );
  }

  /**
   * Method that deletes a user from the potential users table.
   * @param userId Selected user ID.
   */
  public deletePotentialUser(userId: number): Observable<boolean> {
    return this.http.delete<boolean>(this.url + 'users/' + userId + '/delete').pipe(
      map(data => {
        return data;
      })
    );
  }

  /**
   * Method that returns all the users working on the selected project.
   * @param projectId ID of a selected project.
   */
  public getUsersByProject(projectId: number): Observable<Auth[]> {
    return this.http.get<Auth[]>(this.url + projectId + '/users').pipe(
      map(data => {
        const users = [];
        data.forEach(user => {
          users.push(Object.assign(new Auth(), user));
        });
        return users;
      })
    );
  }

  /**
   * Method that checks if the user logged in is a leader on a selected project.
   * @param projectId Selected project ID.
   * @param userId User currently logged in.
   */
  public checkIfLeader(projectId: number, userId: number): Observable<boolean> {
    return this.http.get<boolean>(this.url + projectId + '/leader/' + userId).pipe(
      map(data => {
        return data;
      })
  );
  }

  /** Method that gets statistics for a project for a specific time scope.
   * @param id Selected project ID number.
   * @param start Start of the time scope.
   * @param end End of the time scope.
   */
  public getProjectStatistics(
    id: number,
    start,
    end
  ): Observable<ProjectStatistics> {
    let params = new HttpParams();
    params = params.set('from', start);
    params = params.set('to', end);
    params = params.set('projectId', String(id));
    return this.http
      .get<ProjectStatistics>(this.url + 'statistics', { params: params })
      .pipe(
        map(data => {
          const obj = new ProjectStatistics();
          Object.assign(obj, data);
          Object.assign(obj.users, data.users);
          return obj;
        })
      );
  }

  /**
   * Method that gets statistics for a project by tasks for a specific time scope.
   * @param id Selected project ID number.
   * @param start Start of the time scope.
   * @param end End of the time scope.
   */
  public getProjectStatisticsByTasks(
    id: number,
    start,
    end
  ): Observable<ProjectStatisticsByTasks> {
    let params = new HttpParams();
    params = params.set('from', start);
    params = params.set('to', end);
    params = params.set('projectId', String(id));
    return this.http
      .get<ProjectStatisticsByTasks>(this.url + 'statistics/tasks', {
        params: params
      })
      .pipe(
        map(data => {
          const obj = new ProjectStatisticsByTasks();
          Object.assign(obj, data);
          Object.assign(obj.tasks, data.tasks);
          return obj;
        })
      );
  }

  /**
   * Delete project by given id
   * @param id id of project
   */
  public deleteProject(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.url + '?projectId=' + id).pipe(
      map(data => {
        return data;
      })
    );
  }
}
