import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployeeManageComponent } from '../employee-manage/employee-manage.component';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {
  /** Currently active tab. */
  public activeTab: number = 0;

  /** Employee manage component. */
  @ViewChild('manage', { static: true })
  private manageComp: EmployeeManageComponent;

  constructor() {}

  ngOnInit() {}

  public checkOpenTab(tab: number): void {
    if (tab === 1) {
      this.manageComp.ngOnInit();
    }
  }
}
