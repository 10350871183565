export class UserStatisticsByProjects {
  user: {
    userId: number;
    username: string;
    firstName: string;
    lastName: string;
  };
  projects: {
    projectId: number;
    name: string;
    workingHours: number;
  }[];
  totalHours: number;
}
