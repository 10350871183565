import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { Auth } from './auth.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /** Backend URL. */
  private url: string = environment.url;


  constructor(
    private http: HttpClient
  ) { }


  /**
   * Method that handles login. Returns an Auth object if login is successful.
   * @param formData Object with username and password.
   */
  public login(formData): Observable<Auth> {
    const form = new FormData();
    form.append('username', formData.username);
    form.append('password', formData.password);
    return this.http.post<Auth>(this.url + 'login', form);
  }

  public auth(): Observable<Auth> {
    return this.http.get<any>(this.url + 'auth/user').pipe(
      map(data => {
        const obj = new Auth();
        Object.assign(obj, data);
        Object.assign(obj.systemRole, data.systemRole);
        return obj;
      })
    );

  }

  /** Method that handles logout. */
  public logout(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get<any>(this.url + 'logout', {headers});
  }

  /** Getter that returns true if an user is logged in. */
  get isLoggedIn() {
    return JSON.parse(localStorage.getItem('em2_user')) != null;
  }

  /** Getter that returns the user. */
  get user() {
    return JSON.parse(localStorage.getItem('em2_user'));
  }
}
