import { UserStatisticsByDay } from './../../models/user-statistics-by-day.model';
import { ErrorService } from './../../../../core/error.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatePickerComponent, IDatePickerConfig } from 'ng2-date-picker';
import * as moment from 'moment';
import { EmployeesService } from '../../services/employees.service';
import { UserStatisticsByProjects } from '../../models/user-statistics-by-projects.model';
import { UserStatisticsByProjectsAndTasks } from '../../models/user-statistics-by-projects-and-tasks.model';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ActivatedRoute } from '@angular/router';

export class Stat {
  user: string;
  project: string;
  task?: string;
  workingHours: number;
  from: string;
  to: string;
}

export class DayStat {
  date: string;
  project: string;
  task: string;
  totalHours: number;
}

@Component({
  selector: 'app-employee-statistics',
  templateUrl: './employee-statistics.component.html',
  styleUrls: ['./employee-statistics.component.scss']
})
export class EmployeeStatisticsComponent implements OnInit {
  /** Statistics start date picker component. */
  @ViewChild('startPicker', { static: true })
  public fromPicker: DatePickerComponent;

  /** Statistics cend date picker component. */
  @ViewChild('endPicker', { static: true })
  public toPicker: DatePickerComponent;

  /** Statistics element for printing. */
  @ViewChild('stat', { static: true }) private stat: ElementRef;

  /** Date picker configuration. */
  public datePickerStartConfig: IDatePickerConfig = {
    format: 'YYYY-MM-DD',
    firstDayOfWeek: 'mo',
    disableKeypress: true
  };

  /** Date picker configuration. */
  public datePickerEndConfig: IDatePickerConfig = {
    format: 'YYYY-MM-DD',
    firstDayOfWeek: 'mo',
    disableKeypress: true
  };

  /** Flag that marks whether the statistics by projects is shown. */
  public projectsStatsOpen: boolean = false;

  /** Flag that marks whether the statistics by tasks is shown. */
  public tasksStatsOpen: boolean = false;

  /** Flag that marks whether the statistics by days is shown. */
  public dayStatsOpen: boolean = false;

  /** User statistics by projects. */
  public statisticsByProjects: UserStatisticsByProjects = new UserStatisticsByProjects();

  /** User statistics by tasks. */
  public statisticsByProjectsAndTasks: UserStatisticsByProjectsAndTasks = new UserStatisticsByProjectsAndTasks();

  /** User statistics by days. */
  public statisticsByDays: UserStatisticsByDay[] = [];

  /** Flag that marks that there is no statistic data available. */
  public noData = false;

  /** Statistics for xls conversion. */
  private statXls: any[] = [];

  constructor(
    public _employees: EmployeesService,
    private _error: ErrorService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.fromPicker.inputElementValue = moment()
      .date(1)
      .format('YYYY-MM-DD');
    this.toPicker.inputElementValue = moment()
      .date(moment().daysInMonth())
      .format('YYYY-MM-DD');
    this.openProjectsStatistics();
  }

  /** Method that controls available dates on the End time calendar depending on the user Start time.
   * @param startDate Input start date.
   * @param endPicker DatePicker component for choosing the end date.
   */
  public onChangeStart(
    startDate: moment.Moment,
    endPicker: DatePickerComponent
  ): void {
    if (startDate) {
      /** Minimum End date (minEnd >= Start date). */
      const minEnd = moment(startDate).format('YYYY-MM-DD');
      this.datePickerEndConfig.min = minEnd;
      endPicker.init();
      if (this.projectsStatsOpen) {
        this.openProjectsStatistics();
      } else if (this.tasksStatsOpen) {
        this.openTasksStatistics();
      }
    }
  }

  /** Method that controls available dates on the Start time calendar depending on the user End time change.
   * @param endDate Input end date.
   * @param startPicker DatePicker component for choosing the start time.
   */
  public onChangeEnd(
    endDate: moment.Moment,
    startPicker: DatePickerComponent
  ): void {
    if (endDate) {
      /** Maximum Start date (maxStart <= End date). */
      const maxStart = moment(endDate).format('YYYY-MM-DD');
      this.datePickerStartConfig.max = maxStart;
      startPicker.init();
      if (this.projectsStatsOpen) {
        this.openProjectsStatistics();
      } else if (this.tasksStatsOpen) {
        this.openTasksStatistics();
      }
    }
  }

  /** Method that gets user statistics by projects. */
  public openProjectsStatistics(): void {
    if (!this.fromPicker.inputElementValue) {
      this.fromPicker.inputElementValue = moment()
        .date(1)
        .format('YYYY-MM-DD');
    }
    if (!this.toPicker.inputElementValue) {
      this.toPicker.inputElementValue = moment()
        .date(moment().daysInMonth())
        .format('YYYY-MM-DD');
    }
    const start = this.fromPicker.inputElementValue + ' 00:00:00';
    const end = this.toPicker.inputElementValue + ' 23:59:59';
    this._employees
      .getUserStatisticsByProjects(
        start,
        end,
        this._employees.selectedUser.userId
      )
      .subscribe(
        data => {
          this.statXls = [];
          if (data.projects) {
            data.projects.forEach(project => {
              const statObj = new Stat();
              statObj.user = data.user.firstName + ' ' + data.user.lastName;
              statObj.project = project.name;
              statObj.workingHours = project.workingHours;
              statObj.from = this.fromPicker.inputElementValue;
              statObj.to = this.toPicker.inputElementValue;
              this.statXls.push(statObj);
            });
          }
          this.statisticsByProjects = data;
          this.projectsStatsOpen = true;
          this.tasksStatsOpen = false;
          this.dayStatsOpen = false;
          this.noData = false;
          if (!data.projects) {
            this.noData = true;
          }
        },
        error => {
          this._error.error(error);
          this.noData = true;
        }
      );
  }

  /** Method that gets user statistics by projects and tasks. */
  public openTasksStatistics(): void {
    const start = this.fromPicker.inputElementValue + ' 00:00:00';
    const end = this.toPicker.inputElementValue + ' 23:59:59';
    this._employees
      .getUserStatisticsByTasks(start, end, this._employees.selectedUser.userId)
      .subscribe(
        data => {
          this.statXls = [];
          if (data.projects) {
            data.projects.forEach(project => {
              project.projectTasks.forEach(task => {
                const statObj = new Stat();
                statObj.user = data.user.firstName + ' ' + data.user.lastName;
                statObj.project = project.projectName;
                statObj.task = task.title;
                statObj.workingHours = task.workingHours;
                statObj.from = this.fromPicker.inputElementValue;
                statObj.to = this.toPicker.inputElementValue;
                this.statXls.push(statObj);
              });
            });
          }

          this.statisticsByProjectsAndTasks = data;
          this.projectsStatsOpen = false;
          this.tasksStatsOpen = true;
          this.dayStatsOpen = false;
          this.noData = false;
          if (!data.projects) {
            this.noData = true;
          }
        },
        error => {
          this._error.error(error);
          this.noData = true;
        }
      );
  }

  /** Method that gets user statistics by projects and tasks. */
  public openDayStatistics(): void {
    this.route.params.subscribe(params => {
      this.statisticsByDays = [];
      const start = this.fromPicker.inputElementValue + ' 00:00:00';
      const end = this.toPicker.inputElementValue + ' 23:59:59';
      this._employees
        .getUserStatisticsByDays(start, end, params['id'])
        .subscribe(
          data => {
            this.statisticsByDays = data;
            this.statXls = [];
            if (this.statisticsByDays.length > 0) {
              this.statXls.push({
                User:
                  this.statisticsByDays[0].user.firstName +
                  ' ' +
                  this.statisticsByDays[0].user.lastName
              });
              this.statisticsByDays.forEach(day => {
                if (day.projects) {
                  day.projects.forEach(project => {
                    project.tasks.forEach(task => {
                      const statObj = new DayStat();
                      statObj.date = day.date;
                      statObj.project = project.project.name;
                      statObj.task = task.title;
                      statObj.totalHours = task.workingHours;
                      this.statXls.push(statObj);
                    });
                  });
                }
              });
            } else {
              this.noData = true;
            }
            this.dayStatsOpen = true;
            this.projectsStatsOpen = false;
            this.tasksStatsOpen = false;
            this.noData = false;
            let noDataFlag = false;
            data.forEach(day => {
              if (day.projects) {
                noDataFlag = true;
              }
            });
            if (!noDataFlag) {
              this.noData = true;
            }
          },
          error => {
            this._error.error(error);
            this.noData = true;
          }
        );
    });
  }

  /** Method that creates PDF containing currently open statistics (for printing). */
  public createPDF(): void {
    const data = this.stat.nativeElement;
    html2canvas(data).then(canvas => {
      const imgWidth = 198;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jspdf('p', 'mm', 'a4');
      pdf.addImage(contentDataURL, 'PNG', 5, 5, imgWidth, imgHeight);
      let title;
      if (this.projectsStatsOpen) {
        title = '_statistics-by-projects.pdf';
      } else if (this.tasksStatsOpen) {
        title = '_statistics-by-tasks.pdf';
      }
      pdf.save(
        this.statisticsByProjects.user.firstName +
          '-' +
          this.statisticsByProjects.user.lastName +
          title
      );
    });
  }

  public exportAsExcel(): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.statXls);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    };

    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });

    const data: Blob = new Blob([excelBuffer], {
      type:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    });

    let title;
    if (this.projectsStatsOpen) {
      title = '_statistics-by-projects.xlsx';
    } else if (this.tasksStatsOpen) {
      title = '_statistics-by-tasks.xlsx';
    } else {
      title = '_statistics-by-days.xlsx';
    }
    FileSaver.saveAs(
      data,
      this.fromPicker.inputElementValue +
        ' - ' +
        this.toPicker.inputElementValue +
        '_' +
        this.statisticsByProjects.user.firstName +
        '-' +
        this.statisticsByProjects.user.lastName +
        title
    );
  }
}
