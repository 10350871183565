import { Vacation } from './../models/vacation.model';
import { environment } from './../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VacationService {
  /** Backend URL. */
  private url = environment.url + 'vacationsickleave/';

  constructor(private http: HttpClient) {}

  /** Method that returns all the vacations in the database. */
  public getAllVacations(): Observable<Vacation[]> {
    return this.http.get<Vacation[]>(this.url).pipe(
      map(data => {
        const vacations = [];
        data.forEach(vacation => {
          vacations.push(Object.assign(new Vacation(), vacation));
        });
        return vacations;
      })
    );
  }

  /**
   * Method that returns all the vacations in a specific time scope.
   * @param startDate Start of the time interval.
   * @param endDate End of the time interval.
   */
  public getVacationsByTimeScope(
    startDate: string,
    endDate: string
  ): Observable<Vacation[]> {
    return this.http
      .get<Vacation[]>(this.url + 'time?from=' + startDate + '&to=' + endDate)
      .pipe(
        map(data => {
          const vacations = [];
          data.forEach(vacation => {
            vacations.push(Object.assign(new Vacation(), vacation));
          });
          return vacations;
        })
      );
  }

  /** Method that gets a vacation by its ID. */
  public getVacationById(id: number): Observable<Vacation> {
    return this.http.get<Vacation>(this.url + id).pipe(
      map(data => {
        const obj = new Vacation();
        Object.assign(obj, data);
        return obj;
      })
    );
  }

  /** Method that gets all vacations for a user
   * @param id User ID.
   */
  public getVacationByUserId(id: number): Observable<Vacation[]> {
    return this.http.get<Vacation[]>(this.url + 'user/' + id).pipe(
      map(data => {
        const vacations = [];
        data.forEach(vacation => {
          vacations.push(Object.assign(new Vacation(), vacation));
        });
        return vacations;
      })
    );
  }

  /** Method that gets all vacations for a user
   * @param id User ID.
   */
  public getVacationByUserIdAndTimeScope(
    startDate: string,
    endDate: string,
    id: number
  ): Observable<Vacation[]> {
    return this.http
      .get<Vacation[]>(
        this.url +
          'user/' +
          id +
          '/timescope?from=' +
          startDate +
          '&to=' +
          endDate
      )
      .pipe(
        map(data => {
          const vacations = [];
          data.forEach(vacation => {
            vacations.push(Object.assign(new Vacation(), vacation));
          });
          return vacations;
        })
      );
  }

  /**
   * Method that handles vacation insert.
   * @param start Vacation start date.
   * @param end Vacation end date.
   * @param type Vacation type.
   * @param userId ID of the user which the vacation is inserted for.
   */
  public insertVacation(
    start: string,
    end: string,
    type: string,
    userId: number
  ): Observable<Vacation> {
    let params = new HttpParams();
    params = params.set('from', start);
    params = params.set('to', end);
    params = params.set('type', type);
    params = params.set('userId', String(userId));
    return this.http.post<Vacation>(this.url + 'insert', params).pipe(
      map(data => {
        const obj = new Vacation();
        Object.assign(obj, data);
        return obj;
      })
    );
  }

  public updateVacation(
    start: string,
    end: string,
    type: string,
    id: number
  ): Observable<Vacation> {
    return this.http
      .put<Vacation>(
        this.url +
          'update?from=' +
          start +
          '&to=' +
          end +
          '&type=' +
          type +
          '&id=' +
          id,
        null
      )
      .pipe(
        map(data => {
          const obj = new Vacation();
          Object.assign(obj, data);
          return obj;
        })
      );
  }

  /**
   * Method that approves a vacation.
   * @param id Vacation ID number.
   */
  public approveVacation(formValue): Observable<boolean> {
    return this.http
      .put<boolean>(
        this.url + 'approve?accepted=true&id=' + formValue.id,
        formValue
      )
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  /**
   * Method that deletes a vacation.
   * @param id Vacation ID number.
   */
  public deleteVacation(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.url + '?id=' + id).pipe(
      map(data => {
        return data;
      })
    );
  }
}
