import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  /** Marks whether the checkbox is checked or not. */
  @Input() public checked: boolean;
  /** Event emmitter for the checkbox value. */
  @Output() public checkedChange: EventEmitter<boolean> = new EventEmitter();
  /** Marks whether the checkbox should look like a regular checkbox or like a switch. */
  @Input() public type: string = 'checkbox';

  constructor() { }

  ngOnInit() {
  }

  /** Method that toggles the checkbox. */
  public onChange(): void {
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
  }

}
