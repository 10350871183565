import { Component, OnInit, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

const noop = () => {
};


@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})

export class InputComponent implements ControlValueAccessor {


  /** The internal data model */
  private innerValue: any = '';

  /* Placeholder for the callbacks which are later provided by the Control Value Accessor */
  private onTouchedCallback: () => void = noop;

  /* Placeholder for the callbacks which are later provided by the Control Value Accessor */
  private onChangeCallback: (_: any) => void = noop;

  /** Options for the Autocomplete form:

   * Label that shows inside the input field. */
  @Input() public label: string;

  @Output() public labelChange: EventEmitter<string> = new EventEmitter();

  /** Option that marks whether the form should have a fixed label above the input field or in the input field as a placeholder.
   * If true, the label will show above the input field.
   * If false, the label will show as a placeholder  */
  @Input() public fixedLabel: boolean;

  /** Icon for the input form. */
  @Input() public icon: string;

  /** User input inside the form. */
  public input: string;

  /** Input type. */
  @Input() public type: string;

  constructor() { }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
  }

  // get accessor
  get value(): any {
    return this.innerValue;
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
      this.labelChange.emit(v);
    }
  }

  // Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

}
