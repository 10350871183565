import { ToastService } from './../../shared/toast/toast.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor(
    private _toast: ToastService
  ) {}

  ngOnInit() {
  }

  /** Method that shows the error as a toast.
   * @param message Error message to be shown.
   */
  public error(message): void {
    this._toast.error('Error', message, true, 1500);
  }

}
