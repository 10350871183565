import { ProjectReduced } from './../../calendar/models/project-reduced.model';
export class UserStatisticsByDay {
  date: any;
  user: {
    userId: number,
    username: string,
    firstName: string,
    lastName: string
  };
  projects: {
    project: ProjectReduced,
    tasks: {
      taskId: number,
      title: string,
      workingHours: number
    }[],
    projectTotalWorkingHours: number
  }[];
  dayWorkingHours: number;
}
