import { Client } from '../../clients/models/client.model';
import { UserRoleDto } from '../../employees/models/userRoleDto.model';
export class Project {
  id: number;
  name: string;
  client: Client;
  usersRoles: UserRoleDto[];
  deadLine: any;
  startDate: any;
  endDate: any;
  estimatedDate: any;
  description: string;

  get clientName() {
    return this.client ? this.client.name : null;
  }
}
