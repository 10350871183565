import { ErrorService } from './../../../../core/error.service';
import { EmployeesService } from './../../services/employees.service';
import { Component, OnInit } from '@angular/core';
import { EmployeesComponent } from '../employees/employees.component';
import { Column } from '../../../../shared/data-grid/column.model';
import { Auth } from '../../../../core/auth/auth.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent implements OnInit {
  /** Array of all the employees in the database. */
  public employees: Auth[] = [];

  /** Columns for the data grid. */
  public employeeColumns: Column[] = [
    new Column('Username', 'username'),
    new Column('Name', 'name'),
    new Column('Title', 'title'),
    new Column('E-mail', 'email')
  ];

  constructor(
    public _employeeComp: EmployeesComponent,
    private _employees: EmployeesService,
    private _error: ErrorService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getEmployees();
  }

  /** Method that gets all the users from the database. */
  public getEmployees(): void {
    this._employees.getUsers().subscribe(
      data => {
        this.employees = data;
      },
      error => {
        this._error.error(error);
      }
    );
  }

  /**
   * Method that selects a user to be edited.
   * @param user User to be edited.
   */
  public getUserToEdit(user): void {
    this.router.navigate([`/employees/${user.userId}`]);
  }

  /** Add new user */
  public addEmployee(): void {
    this.router.navigate([`/employees/new`]);
  }

  /**
   * Method that opens statistics for selected user.
   * @param user Selected user.
   */
  public openEmployeeStatistics(user: Auth): void {
    this.router.navigate(['employees/statistics/' + user.userId]);
    this._employees.selectedUser = user;
  }
}
