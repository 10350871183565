import { Router } from '@angular/router';
import { Notification } from './notification.model';
import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { SidebarService } from '../sidebar.service';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})



export class HeaderComponent implements OnInit {
  /** Flag that marks whether the sidebar is hidden or shown. */
  public sidebarHidden: boolean = false;

  /** Flag that marks whether the Avatar menu is hidden or shown. */
  public menuShown: boolean = false;

  /** Flag that marks whether the Notification menu is hidden or shown. */
  public notifShown: boolean = false;

  /** Array that contains all the notifications. */
  public notifications: Notification[] = [];

  /** Number of unread notifications. */
  public unreadNotifications: number = 0;

  /** Flag that marks whether the Notification Close button has been clicked so that Notification menu doesn't close when clicked on Close button. */
  private closeFlag: boolean = false;

  /** User currently logged in. */
  public username: string;

  constructor(
    public _sidebar: SidebarService,
    private _elemref: ElementRef,
    private _auth: AuthService,
    private router: Router
  ) {
    this.username = _auth.user.username;
  }

  ngOnInit() { }

  /** Method that hides Avatar Menu when clicked somewhere else. */
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this._elemref.nativeElement.contains(event.target)) {
      this.menuShown = false;
      if (this.closeFlag === false) {
        this.notifShown = false;
      }
      this.closeFlag = false;
    }
  }

  /** Method that shows and hides sidebar. */
  public toggleSidebar(): void {
    this.sidebarHidden = this._sidebar.toggleSidebar();
  }

  /** Method that shows and hides Avatar Menu. */
  public toggleMenu(): void {
    this.menuShown = !this.menuShown;
    this.notifShown = false;
  }

  /** Method that marks a notification as read.
   * @param index Index of the notification in the notification array.
  */
  public readNotification(index: number): void {
    this.notifications[index].notifRead = true;
    this.unreadNotifications--;
  }

  /** Method that deletes a notificiation when clicked on X.
   * @param index Index of the notification in the notification array.
   */
  public deleteNotification(index: number): void {
    if (this.notifications[index].notifRead === false) {
      this.unreadNotifications--;
    }
    this.notifications.splice(index, 1);
    this.closeFlag = true;
  }

  /** Method that opens or closes the notification menu. */
  public toggleNotifMenu(): void {
    this.notifShown = !this.notifShown;
    this.menuShown = false;
  }

  /** Method that handles user logout. */
  public logout(): void {
    this._auth.logout()
      .subscribe(
        data => {
          localStorage.removeItem('em2_user');
          this.router.navigate(['login']);
        }
      );
  }
}
