import { map } from 'rxjs/operators';
import { AuthService } from './../../../core/auth/auth.service';
import { UserStatistics } from './../models/user-statistics.model';
import { UserStatisticsByProjectsAndTasks } from './../models/user-statistics-by-projects-and-tasks.model';
import { Auth } from './../../../core/auth/auth.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { UserStatisticsByProjects } from '../models/user-statistics-by-projects.model';
import { UserStatisticsByDay } from '../models/user-statistics-by-day.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService {
  /** Backend URL. */
  private url: string = environment.url + 'users/';

  /** Currently selected user for statistics display. */
  public selectedUser: Auth = new Auth();

  constructor(private http: HttpClient, private _auth: AuthService) {
  }

  /** Method that gets all the users from the database. */
  public getUsers(): Observable<Auth[]> {
    return this.http.get<Auth[]>(this.url + 'all').pipe(
      map(data => {
        const users = [];
        data.forEach(user => {
          users.push(Object.assign(new Auth(), user));
        });
        return users;
      })
    );
  }

  /** Method that gets a user from the database based on user ID.
   * @param id User ID.
   */
  public getUserById(id: number): Observable<Auth> {
    return this.http.get<Auth>(this.url + id).pipe(
      map(data => {
        const obj = new Auth();
        Object.assign(obj, data);
        Object.assign(obj.systemRole, data.systemRole);
        return obj;
      })
    );
  }

  /** Method that registers a new user to the database.
   * @param formValue Registration data.
   */
  public registerUser(formValue: Object): Observable<Auth> {
    return this.http.post<Auth>(this.url + 'registration', formValue).pipe(
      map(data => {
        const obj = new Auth();
        Object.assign(obj, data);
        return obj;
      })
    );
  }

  /**
   * Method that gets user statistics by projects for a specific time scope.
   * @param start Start of the time scope.
   * @param end End of the time scope.
   */
  public getUserStatisticsByProjects(
    start: string,
    end: string,
    userId?: number
  ): Observable<UserStatisticsByProjects> {
    let params = new HttpParams();
    params = params.set('from', start);
    params = params.set('to', end);
    if (!userId) {
      userId = this._auth.user.userId;
    }
    params = params.set('userId', String(userId));
    return this.http
      .get<UserStatisticsByProjects>(this.url + this.appendAdmin() + 'statistics', {
        params: params
      })
      .pipe(
        map(data => {
          const obj = new UserStatisticsByProjects();
          Object.assign(obj, data);
          return obj;
        })
      );
  }

  private appendAdmin() {
    if (this._auth.user.userId === 2) {
      return 'admin/';
    }
    return "";
  }

  /**
   * Method that gets user statistics by projects and tasks for a specific time scope.
   * @param start Start of the time scope.
   * @param end End of the time scope.
   */
  public getUserStatisticsByTasks(
    start: string,
    end: string,
    userId?: number
  ): Observable<UserStatisticsByProjectsAndTasks> {
    let params = new HttpParams();
    params = params.append('from', start);
    params = params.append('to', end);
    if (!userId) {
      userId = this._auth.user.userId;
    }
    params = params.append('userId', String(userId));
    return this.http
      .get<UserStatisticsByProjectsAndTasks>(this.url + 'taskstatistics', {
        params: params
      })
      .pipe(
        map(data => {
          const obj = new UserStatisticsByProjectsAndTasks();
          Object.assign(obj, data);
          return obj;
        })
      );
  }

  public getUserStatisticsByDays(
    start: string,
    end: string,
    userId?: string
  ): Observable<UserStatisticsByDay[]> {
    let params = new HttpParams();
    params = params.append('from', start);
    params = params.append('to', end);
    if (!userId) {
      userId = this._auth.user.userId;
    }
    params = params.append('userId', userId);

    return this.http
      .get<UserStatisticsByDay[]>(this.url + 'daystatistics', {
        params: params
      })
      .pipe(
        map(data => {
          const stats = [];
          data.forEach(user => {
            stats.push(Object.assign(new UserStatisticsByDay(), user));
          });
          return stats;
        })
      );
  }

  /**
   * Method that handles user edit.
   * @param formValue New user info.
   */
  public editUser(formValue): Observable<Auth> {
    return this.http
      .put<Auth>(
        this.url +
        '?firstName=' +
        formValue.firstName +
        '&lastName=' +
        formValue.lastName +
        '&title=' +
        formValue.title +
        '&workPlace=' +
        formValue.workPlace +
        '&email=' +
        formValue.email +
        '&userId=' +
        formValue.userId,
        null
      )
      .pipe(
        map(data => {
          const obj = new Auth();
          Object.assign(obj, data);
          return obj;
        })
      );
  }

  /**
   * Method that handles password change.
   * @param formValue old and new password.
   */
  public changePassword(formValue): Observable<boolean> {
    let params = new HttpParams();
    params = params.set('oldPassword', formValue.oldPassword);
    params = params.set('newPassword', formValue.newPassword);
    params = params.set('userId', formValue.userId);
    return this.http.put<boolean>(this.url + 'password', params).pipe(
      map(data => {
        return data;
      })
    );
  }
}
