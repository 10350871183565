export class MenuItem {
  icon: string;
  name: string;
  url: string;
  active: boolean;

  constructor(icon?: string, name?: string, url?: string) {
    this.icon = icon;
    this.name = name;
    this.url = url;
    this.active = false;
  }
}
