import { InputComponent } from './../../../../shared/input/input.component';
import { AuthService } from './../../../../core/auth/auth.service';
import { ErrorService } from './../../../../core/error.service';
import { ToastService } from './../../../../shared/toast/toast.service';
import { EmployeesService } from './../../../employees/services/employees.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
  }


}
