import { AuthService } from "./../../../core/auth/auth.service";
import { Auth } from "./../../../core/auth/auth.model";
import { CalendarEvent } from "./../models/calendar-event.model";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Project } from "../../projects/models/project.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class CalendarService {
  /** Backend URL. */
  private url: string = environment.url + "events/";

  /** Flag that marks the page is loaded so the view wouldn't reset on every timer change or drag and drop. */
  public loaded: boolean = false;

  /** Timer that checks for the current time every minute. */
  public timer: any;

  /** Current date and time. */
  public currentTime: moment.Moment = moment();

  /** Top position of the line that marks the current time. */
  public lineTop: number;

  /** project chosen via dropdown menu */
  public selectedProject: Project;

  /** user chosen via dropdown menu */
  public selectedUser: Auth;

  /** Array of user arrays to be displayed for each day on the calendar for multiple users display. */
  public usersToDisplay: Auth[][] = [];

  /** True if calendar with multiple users is shown, false if calendar with one user is shown. */
  public multipleUsers: boolean = false;

  /** True if calendar with multiple projects is shown, false if calendar with one project is shown. */
  public multipleProjects: boolean = true;

  constructor(private http: HttpClient, private _auth: AuthService) {}

  /** Method that uses timer to set the current time so it can be marked on the calendar. */
  public setTime(): void {
    this.currentTime = moment();
    this.lineTop = (75 / 60) * this.currentTime.minutes();
    setTimeout(() => {
      this.loaded = true;
    }, 500);
    this.timer = setInterval(() => {
      this.currentTime = moment();
      this.lineTop = (75 / 60) * this.currentTime.minutes();
    }, 60000);
  }

  /** Method that gets the calendar events from the database.
   * @param start Chosen start date.
   * @param end Chosen end date.
   */
  public getCalendar(start: string, end: string): Observable<CalendarEvent[]> {
    let urlEnd;
    let params = new HttpParams();
    params = params.append("from", start);
    params = params.append("to", end);
    if (!this.multipleUsers) {
      params = this.selectedUser
        ? params.append("username", this.selectedUser.username)
        : params.append("username", this._auth.user.username);
      urlEnd = "users";
    } else {
      urlEnd = "projects";
    }
    if (!this.multipleProjects) {
      params = params.append("projectId", String(this.selectedProject.id));
    }
    return this.http
      .get<CalendarEvent[]>(this.url + urlEnd, { params: params })
      .pipe(
        map((data) => {
          const events = [];
          data.forEach((item) => {
            events.push(
              Object.assign(new CalendarEvent(item.date, item.data), item)
            );
          });
          return events;
        })
      );
  }

  /**
   * Method that handles inserting an event to the database.
   * @param formData Event parameters.
   */
  public insertEvent(formData): Observable<boolean> {
    const form = Object.assign({}, formData);
    form.startDateTime = moment(form.startDateTime).format("YYYY-MM-DDTHH:mm");
    form.endDateTime = moment(form.endDateTime).format("YYYY-MM-DDTHH:mm");
    return this.http.post<boolean>(this.url, form).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * Method that handles updating an event to the database.
   * @param formData Event parameters.
   */
  public updateEvent(formData): Observable<boolean> {
    const form = Object.assign({}, formData);
    form.startDateTime = moment(form.startDateTime).format("YYYY-MM-DDTHH:mm");
    form.endDateTime = moment(form.endDateTime).format("YYYY-MM-DDTHH:mm");
    return this.http.put<boolean>(this.url, form).pipe(
      map((data) => {
        return data;
      })
    );
  }
  /**
   * Method that handles deleting an event from the database.
   * @param id Event ID.
   */
  public deleteEvent(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.url + id).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
