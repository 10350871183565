import { Injectable } from '@angular/core';
import { Toast } from './toast.model';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  /** Array where the toast messages are stored. */
  public toastBox: Toast[] = [];

  constructor() { }

  /** Method that creates Success toast message.
   * @param title Title of the toast message.
   * @param text Text of the toast message.
   * @param autoClose Flag that marks whether the toast will autoclose after specified time.
   * @param closeTime Duration of the toast in miliseconds. The toast closes after the specified time has passed (if autoClose is true).
    */
  public success(title: string, text: string, autoClose?: boolean, closeTime?: number): void {
    this.showToast(new Toast(title, text, 'success', closeTime, autoClose));
  }

  /** Method that creates Error toast message.
   * @param title Title of the toast message.
   * @param text Text of the toast message.
   * @param autoClose Flag that marks whether the toast will autoclose after specified time.
   * @param closeTime Duration of the toast in miliseconds. The toast closes after the specified time has passed (if autoClose is true).
   */
  public error(title: string, text: string, autoClose?: boolean, closeTime?: number): void {
    this.showToast(new Toast(title, text, 'error', closeTime, autoClose));
  }

  /** Method that creates Warning toast message.
   * @param title Title of the toast message.
   * @param text Text of the toast message.
   * @param autoClose Flag that marks whether the toast will autoclose after specified time.
   * @param closeTime Duration of the toast in miliseconds. The toast closes after the specified time has passed (if autoClose is true).
   */
  public warning(title: string, text: string, autoClose?: boolean, closeTime?: number): void {
    this.showToast(new Toast(title, text, 'warning', closeTime, autoClose));
  }

  /** Method that creates Info toast message.
   * @param title Title of the toast message.
   * @param text Text of the toast message.
   * @param autoClose Flag that marks whether the toast will autoclose after specified time.
   * @param closeTime Duration of the toast in miliseconds. The toast closes after the specified time has passed (if autoClose is true).
   */
  public info(title: string, text: string, autoClose?: boolean, closeTime?: number): void {
    this.showToast(new Toast(title, text, 'info', closeTime, autoClose));
  }

  /** Method that shows toast message.
   * @param toast Toast which will show on the screen.
  */
  private showToast(toast: Toast): void {
    /** Maximum of 5 toast messages can be shown. */
    if (this.toastBox.length === 5) {
      this.toastBox.shift();
    }
    this.toastBox.push(toast);

    const time = toast.closeTime / 50;

    if (toast.autoClose) {
      toast.timer = setInterval(() => {
        toast.loaded += 2;
        if (toast.loaded >= 100) {
          this.closeToast(toast);
        }
      }, time);
    }
  }

  /** Method that closes a specific Toast message (when cliked on Close button).
   * @param toast Toast which will be closed.
  */
  public closeToast(toast: Toast): void {
    clearInterval(toast.timer);
    const index = this.toastBox.indexOf(toast);
    this.toastBox.splice(index, 1);
  }

  /** Method that closes all toast messages. */
  public closeAll(): void {
    const length = this.toastBox.length;
    for (let i = 0; i < length; i++) {
      this.closeToast(this.toastBox[i]);
    }
  }
}
