import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input/input.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { TabsComponent } from './tab/tabs/tabs.component';
import { TabComponent } from './tab/tab/tab.component';
import { ToastComponent } from './toast/toast/toast.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ConfirmModalDialogComponent } from './confirm-modal-dialog/confirm-modal-dialog.component';
import { DataGridComponent } from './data-grid/data-grid.component';
import { DataGridSidebarComponent } from './data-grid/data-grid-sidebar/data-grid-sidebar.component';
import { DataGridPaginationComponent } from './data-grid/data-grid-pagination/data-grid-pagination.component';
import { DataGridCardViewComponent } from './data-grid/data-grid-card-view/data-grid-card-view.component';
import { DataGridListViewComponent } from './data-grid/data-grid-list-view/data-grid-list-view.component';
@NgModule({
  imports: [CommonModule, FormsModule],
  exports: [
    InputComponent,
    DropdownComponent,
    AutocompleteComponent,
    TabsComponent,
    TabComponent,
    ToastComponent,
    CheckboxComponent,
    ConfirmModalDialogComponent,
    DataGridComponent,
    DataGridSidebarComponent,
    DataGridPaginationComponent,
    DataGridCardViewComponent,
    DataGridListViewComponent
  ],
  providers: [],
  declarations: [
    InputComponent,
    DropdownComponent,
    AutocompleteComponent,
    TabsComponent,
    TabComponent,
    ToastComponent,
    CheckboxComponent,
    ConfirmModalDialogComponent,
    DataGridComponent,
    DataGridSidebarComponent,
    DataGridPaginationComponent,
    DataGridCardViewComponent,
    DataGridListViewComponent
  ]
})
export class SharedModule {}
