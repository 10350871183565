export class Auth {
  userId: number;
  username: string;
  title: string;
  workPlace: string;
  firstName: string;
  lastName: string;
  email: string;
  systemRole: { id: number, name: string };
  permissions: { permissionId: number, name: string, description: string }[] = [];

  constructor() {
  }

   get name() {
    return this.firstName && this.lastName ? this.firstName + ' ' + this.lastName : null;
  }
}
