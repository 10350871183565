import { EmployeeStatisticsComponent } from './components/employee-statistics/employee-statistics.component';
import { AdminGuard } from './../../core/auth/admin.guard';
import { AuthGuard } from './../../core/auth/auth.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmployeesComponent } from './components/employees/employees.component';
import { EmployeeComponent } from './components/employee.component';
import { EmployeeEditComponent } from './components/employee-edit/employee-edit.component';

export const routes: Routes = [
  {
    path: '',
    component: EmployeeComponent,
    children: [
      {
        path: '',
        component: EmployeesComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'statistics/:id',
        component: EmployeeStatisticsComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: ':id',
        component: EmployeeEditComponent,
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'new',
        component: EmployeeEditComponent,
        canActivate: [AuthGuard, AdminGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmployeesRoutingModule {}
