import { UserProjectEvent } from './user-project-event.model';
import { Moment } from 'moment';
import { ProjectEvent } from './project-event.model';
/** Class that consists of date and events for each date with their start times, end times and titles. */
export class CalendarEvent {
  /** Calendar date, contains events. */
  public date: Moment;
  /** Array of events for the date. */
  public data: any[]; // ProjectEvent[] | UserProjectEvent[];

  constructor(date: any, data: any[]) {
    this.date = date;
    this.data = data;
  }
}
