import { EmployeeStatisticsComponent } from './../../../employees/components/employee-statistics/employee-statistics.component';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @ViewChild('employeestatistics', { static: true })
  private employeeStatistics: EmployeeStatisticsComponent;

  constructor() {}

  ngOnInit() {
    this.employeeStatistics.openProjectsStatistics();
  }
}
