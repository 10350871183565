import { ProjectReduced } from '../../calendar/models/project-reduced.model';

export class ProjectStatisticsByTasks {
  project: ProjectReduced;
  tasks: {
    taskId: number;
    title: string;
    workingHours: number;
  }[];
  projectTotalWorkingHours: number;
}
