import { ErrorService } from './../error.service';
import { AuthService } from './../auth/auth.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class BaseResolver implements Resolve<any> {

  constructor(
    private _auth: AuthService,
    private _error: ErrorService,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> {
    return this._auth.auth().pipe(

      map(data => {
        if (data) {
          localStorage.setItem('em2_user', JSON.stringify(data));
        } else {
          this.router.navigate(['/login']);
        }
      }),
      catchError(error => {
        this.router.navigate(['/login']);
        return of(null);
      })
    );
  }
}
