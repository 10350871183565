import { TabComponent } from '../tab/tab.component';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  /** Array where tabs are stored. */
  public tabs: TabComponent[] = [];
  /** Index number of the currently active tab. */
  @Input() public activeTab: number;
  /** Event emitter for the tab change. */
  @Output() public activeTabChange: EventEmitter<number> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  /** Method for adding tabs from the Tab component.
   * @param tab Tab to be added.
  */
  public addTab(tab: TabComponent): void {
    this.tabs.push(tab);
  }

  /** After the tabs have loaded, an active tab is selected. */
  // tslint:disable-next-line:use-life-cycle-interface
  public ngAfterContentInit() {
    const activeTabs = this.tabs.filter((tab) => tab.active);
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs[this.activeTab], this.activeTab);
    }
  }

  /** Method for selecting an active tab.
   * @param tab Tab to be selected.
   * @param index Index of the tab to be selected.
  */
  public selectTab(tab: TabComponent, index: number) {
    if (!tab.disabled) {
      // tslint:disable-next-line:no-shadowed-variable
      this.tabs.forEach(tab => tab.active = false);
      tab.active = true;
      this.activeTab = index;
      this.activeTabChange.emit(this.activeTab);
    }
  }

}
