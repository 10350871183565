import { RouterModule } from '@angular/router';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeesComponent } from './components/employees/employees.component';
import {
  FormsModule,
  ReactiveFormsModule
} from '../../../../node_modules/@angular/forms';
import { DpDatePickerModule } from 'ng2-date-picker';
import { EmployeeListComponent } from './components/employee-list/employee-list.component';
import { EmployeeManageComponent } from './components/employee-manage/employee-manage.component';
import { EmployeeStatisticsComponent } from './components/employee-statistics/employee-statistics.component';
import { EmployeesRoutingModule } from './employees.routing';
import { EmployeeEditComponent } from './components/employee-edit/employee-edit.component';
import { EmployeeComponent } from './components/employee.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    DpDatePickerModule,
    RouterModule,
    EmployeesRoutingModule
  ],
  exports: [
    EmployeesComponent,
    EmployeeListComponent,
    EmployeeManageComponent,
    EmployeeStatisticsComponent
  ],
  declarations: [
    EmployeesComponent,
    EmployeeListComponent,
    EmployeeManageComponent,
    EmployeeStatisticsComponent,
    EmployeeEditComponent,
    EmployeeComponent
  ]
})
export class EmployeesModule {}
