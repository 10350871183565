import { Column } from './../column.model';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-data-grid-list-view',
  templateUrl: './data-grid-list-view.component.html',
  styleUrls: ['./data-grid-list-view.component.scss']
})
export class DataGridListViewComponent implements OnInit {

  /** Defined input data */
  @Input() public data: any;

  /** Defined input columns */
  @Input() public columns: Column[];

  /** Defined current view showed to user */
  @Input() public currentView: string;
  @Output() public viewChanged: EventEmitter<string> = new EventEmitter();

  // tslint:disable-next-line:no-output-on-prefix
  @Output() public onEditButton: EventEmitter<Object> = new EventEmitter();

  // tslint:disable-next-line:no-output-on-prefix
  @Output() public onDeleteButton: EventEmitter<Object> = new EventEmitter();

  // tslint:disable-next-line:no-output-on-prefix
  @Output() public onStatButton: EventEmitter<Object> = new EventEmitter();

  // tslint:disable-next-line:no-output-on-prefix
  @Output() public onClick: EventEmitter<Object> = new EventEmitter();

  /** User defined variable to show or hide edit button */
  @Input() public editButton;

  /** User defined variable to show or hide delete button */
  @Input() public deleteButton;

  /** User defined variable to show or hide statistics button */
  @Input() public statButton = false;

  /** User defined row title. */
  @Input() public rowTitle;


  /** Customize edit button for each row */
  @Input() public customEdit: boolean = false;

  /** is data grid row editable */
  @Input() public editables: boolean[] = [];

  constructor(

  ) { }

  ngOnInit() {
  }

  /**
   * Method for changing view on user screen
   * @param {string} param current view
   */
  public changeView(param): void {
    this.currentView = param;
    this.viewChanged.emit(this.currentView);
  }

  /** Method for opening statistics */
  public openStats(item, event): void {
    this.onStatButton.emit(item);
    event.stopPropagation();
  }
  /**
   * Method for invoking and passing current object that is clicked for edit
   * @param item passed object
   * @param event Click event.
   */
  public editItem(item, event): void {
    this.onEditButton.emit(item);
    event.stopPropagation();
  }

  /**
   * Method for invoking and passing current object that is clicked for delete
   * @param item passed object
   * @param event Click event.
   */
  public deleteItem(item, event): void {
    this.onDeleteButton.emit(item);
    event.stopPropagation();
  }

  public clickOnItem(item): void {
    this.onClick.emit(item);
  }


}
