export class Vacation {
  id: number;
  user: {
    userId: number,
    username: string,
    firstName: string,
    lastName: string
  };
  startDate: any;
  endDate: any;
  accepted: boolean;
  type: string;
  editedBy: number;
  acceptedBy: number;
}
