import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorComponent } from './error/error.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private _errorComp: ErrorComponent,
  ) {
  }

  public error(error: HttpErrorResponse): void {
    let message;
    switch (error.status) {
      case 403:
        message = 'Access denied';
        break;
      case 500:
        message = 'Internal server error';
        break;
      default:
        error.error ? message = error.error.message : message = 'Unknown error';
        break;
    }
    this._errorComp.error(message);
  }
}
