import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  ElementRef,
  HostListener,
  forwardRef
} from '@angular/core';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true
    }
  ]
})
export class AutocompleteComponent implements OnInit {
  @Input() public input;

  /** Label that shows above the input field. If omitted, the label won't show. */
  @Input() public fixedLabel: string;

  /** Placeholder for the input field. */
  @Input() public placeholder: string;

  /** Dataset for the autocompletion. */
  @Input() public data: any[];

  /** When the dataset is an array of objects, this option should be used in order to choose which object attribute will be shown.
   *           If the dataset is not an array of objects, this option must be omitted. */
  @Input() public field: string;

  /** Flag that marks whether the data is shown or not. */
  public dataShown: boolean = false;

  /** Array where the items filtered by the filter() function are stored. */
  public filteredData: any[] = [];

  /** Variable where the selected item is stored.  */
  @Input() public selected: any = '';

  /** Event Emitter for the item selection. */
  @Output() public selectedChange: EventEmitter<any> = new EventEmitter();

  /** Iterator for the arrow key navigation.  */
  private index: number = 0;

  /** Flag needed so that, when navigating autocomplete menu with the up and down keys,
   * the index variable doesn't increase (and skips the first or the last item) if the up or down key are pressed for the first time. */
  private firstKeyUpDownFlag: boolean = true;

  constructor(private _elemref: ElementRef) {}

  ngOnInit() {}

  writeValue(value: any) {
    if (value !== undefined) {
      this.selected = value;
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  /** Method that hides Autocomplete list when clicked somewhere else. */
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this._elemref.nativeElement.contains(event.target)) {
      this.dataShown = false;
      this.filteredData = [];
    }
  }

  /** Method for filtering list items based on a search term.
   * @param term Search term.
   * @param event Keyboard event.
   */
  public filter(term: string, event: KeyboardEvent): any[] {
    if (
      (event.which !== 38 && event.which !== 40) ||
      this.firstKeyUpDownFlag === true
    ) {
      this.dataShown = true;
      if (this.field) {
        this.filteredData = this.data.filter(item =>
          item[this.field]
            .toString()
            .toLowerCase()
            .startsWith(term.toLowerCase())
        );
      } else {
        this.filteredData = this.data.filter(item =>
          item
            .toString()
            .toLowerCase()
            .startsWith(term.toLowerCase())
        );
      }
      return this.filteredData;
    }
  }

  /** Method for selecting an item.
   * @param item Item to be selected.
   */
  public select(item: any): void {
    this.selected = item;
    this.propagateChange(this.selected);
    this.selectedChange.emit(this.selected);
  }

  /** Method for showing/hiding all data when clicked on the icon. */
  public toggleAllData(): any[] {
    this.dataShown = !this.dataShown;
    if (this.dataShown) {
      this.filteredData = this.data;
      return this.filteredData;
    } else {
      this.filteredData = [];
      return this.filteredData;
    }
  }

  /** Method that checks if filtered data exists so the databox can show or not show based on the result. */
  public dataExists(): boolean {
    return this.filteredData.length > 0;
  }

  /** Method that moves the autocomplete item selection down when the arrow down is pressed.
   * @param event Keyboard event.
   */
  public moveDown(): void {
    if (
      this.index < this.filteredData.length - 1 &&
      this.firstKeyUpDownFlag === false
    ) {
      this.index++;
    }
    if (this.index < this.filteredData.length) {
      this.select(this.filteredData[this.index]);
      this.firstKeyUpDownFlag = false;
    }
  }

  /** Method that moves the autocomplete item selection up when the arrow up is pressed.
   * @param event Keyboard event.
   */
  public moveUp(): void {
    if (this.index > 0 && this.firstKeyUpDownFlag === false) {
      this.index--;
    }
    if (this.index >= 0) {
      this.select(this.filteredData[this.index]);
      this.firstKeyUpDownFlag = false;
    }
  }

  /** Method that changes the autocomplete input field based on the user selection (on click).
   * @param item Item to be selected.
   */
  public clickChange(item: any): void {
    this.select(item);
    this.input = this.selected[this.field];
    this.toggleAllData();
  }

  /** Method that changes the autocomplete input field based on the user selection (on Enter key). */
  public arrowChange(): void {
    if (this.input === '') {
      this.filteredData = this.data;
      this.select(this.filteredData[this.index]);
    } else {
      this.select(this.filteredData[0]);
    }
    this.input = this.selected[this.field];
    this.toggleAllData();
  }
}
