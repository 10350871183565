import { ErrorService } from './../../../../core/error.service';
import { ToastService } from './../../../../shared/toast/toast.service';
import { EmployeesService } from './../../../employees/services/employees.service';
import { AuthService } from './../../../../core/auth/auth.service';
import { InputComponent } from './../../../../shared/input/input.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-profile-edit-password',
  templateUrl: './profile-edit-password.component.html',
  styleUrls: ['./profile-edit-password.component.scss']
})
export class ProfileEditPasswordComponent implements OnInit {
  /** Form for user editing. */
  public changePasswordForm = new FormGroup({
    userId: new FormControl(''),
    oldPassword: new FormControl(''),
    newPassword: new FormControl('')
  });

  /** Old password input component. */
  @ViewChild('oldpassword', { static: true })
  private oldPasswordInput: InputComponent;
  /** New password input component. */
  @ViewChild('newpassword', { static: true })
  private newPasswordInput: InputComponent;
  /** Confirm new password input component. */
  @ViewChild('confirmpassword', { static: true })
  private confirmPasswordInput: InputComponent;

  constructor(
    private _auth: AuthService,
    private _employees: EmployeesService,
    private _toast: ToastService,
    private _error: ErrorService
  ) {}

  ngOnInit() {}

  /** Method that handles password change. */
  public changePassword(): void {
    console.log(this.confirmPasswordInput);
    console.log(this.oldPasswordInput);
    if (this.newPasswordInput.input === this.confirmPasswordInput.input) {
      this.changePasswordForm.setValue({
        userId: this._auth.user.userId,
        oldPassword: this.oldPasswordInput.input,
        newPassword: this.newPasswordInput.input
      });
      this._employees.changePassword(this.changePasswordForm.value).subscribe(
        data => {
          this._toast.success(
            'Success',
            'Successfully changed password',
            true,
            1500
          );
        },
        error => {
          this._error.error(error);
        }
      );
    } else {
      this._toast.error('Error', 'Passwords do not match!', true, 1500);
    }
  }
}
