import { AuthService } from './../../../core/auth/auth.service';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Message } from '../models/message.model';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  /** Backend URL. */
  private url: string = environment.url + 'messages/';

  public selectedMessage: Message;

  constructor(private http: HttpClient, private _auth: AuthService) {}

  /** Method that gets all the messages from the database valid in the current day.
   * @param today today's date.
   */
  public getAllMessages(): Observable<Message[]> {
    let params = new HttpParams();
    params = params.set('userId', this._auth.user.userId);
    return this.http.get<Message[]>(this.url + 'day', { params }).pipe(
      map(data => {
        const messages = [];
        data.forEach(message => {
          messages.push(Object.assign(new Message(), message));
        });
        return messages;
      })
    );
  }

  /**
   * Method that gets all the messages valid in the current date for a selected project.
   * @param today today's date.
   * @param projectId ID of a selected project.
   */
  public getMessagesByProject(projectId): Observable<Message[]> {
    let params = new HttpParams();
    params = params.set('userId', this._auth.user.userId);
    return this.http.get<Message[]>(this.url + projectId, { params }).pipe(
      map(data => {
        const messages = [];
        data.forEach(message => {
          messages.push(Object.assign(new Message(), message));
        });
        return messages;
      })
    );
  }

  /**
   * Method that handles message insert
   * @param formValue message object
   */
  public insertMessage(formValue): Observable<Message> {
    return this.http.post<Message>(this.url, formValue).pipe(
      map(data => {
        const obj = new Message();
        Object.assign(obj, data);
        return obj;
      })
    );
  }

  /**
   * Method that handles message update.
   * @param formValue Message parameters.
   */
  public updateMessage(formValue): Observable<Message> {
    return this.http.put<Message>(this.url, formValue).pipe(
      map(data => {
        const obj = new Message();
        Object.assign(obj, data);
        return obj;
      })
    );
  }

  /**
   * Method that handles message delete.
   * @param id Message ID.
   */
  public deleteMessage(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.url + id).pipe(
      map(data => {
        return data;
      })
    );
  }
}
