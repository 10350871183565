import { DropdownComponent } from './../../../../shared/dropdown/dropdown.component';
import { PotentialUserLeader } from './../../../projects/models/potential-user-leader.model';
import { ErrorService } from './../../../../core/error.service';
import { ToastService } from './../../../../shared/toast/toast.service';
import { ProjectService } from './../../../projects/services/project.service';
import { ConfirmModalDialogComponent } from './../../../../shared/confirm-modal-dialog/confirm-modal-dialog.component';
import { Auth } from './../../../../core/auth/auth.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Column } from '../../../../shared/data-grid/column.model';
import { EmployeesService } from '../../services/employees.service';

@Component({
  selector: 'app-employee-manage',
  templateUrl: './employee-manage.component.html',
  styleUrls: ['./employee-manage.component.scss']
})
export class EmployeeManageComponent implements OnInit {
  /** Columns for the data grid. */
  public employeeColumns: Column[] = [
    new Column('Username', 'userUsername'),
    new Column('First name', 'userFirstName'),
    new Column('Last name', 'userLastName')
  ];

  /** Array of all the employees that can be project leaders on a project. */
  public potentialProjectLeaders: PotentialUserLeader[] = [];

  /** Array of all the employees that can be project users on a project. */
  public potentialProjectUsers: PotentialUserLeader[] = [];

  /** All users from the database. */
  public users: Auth[] = [];

  /** Currently selected user. */
  public selectedUser: Auth;

  /** 'Project user' or 'Project leader' */
  private dialogOpen: string;

  /** Selected user to be deleted from the potential users/leaders table. */
  private userToDelete: any;

  /** Defines whether the method deletePotentialUser or deletePotentialLeader should be called. 'leader' or 'user' */
  public deleting: string;

  /** Delete dialog */
  public deleteDialog = false;

  /** add dialog */
  public addDialog = false;

  constructor(
    private _employees: EmployeesService,
    private _project: ProjectService,
    private _toast: ToastService,
    private _error: ErrorService
  ) {}

  ngOnInit() {
    this.getPotentialProjectLeaders();
    this.getPotentialProjectUsers();
    this.getAllUsers();
  }

  /** Return all potentila project leaders */
  public getPotentialProjectLeaders(): void {
    this.potentialProjectLeaders = [];
    this._project.getPotentialProjectLeaders().subscribe(
      data => {
        this.potentialProjectLeaders = data;
      },
      error => {
        this._error.error(error);
      }
    );
  }

  /** Return all potential project users */
  public getPotentialProjectUsers(): void {
    this.potentialProjectUsers = [];
    this._project.getPotentialProjectUsers().subscribe(
      data => {
        this.potentialProjectUsers = data;
      },
      error => {
        this._error.error(error);
      }
    );
  }

  /** Method that gets all the users from the database. */
  public getAllUsers(): void {
    this._employees.getUsers().subscribe(
      data => {
        this.users = data;
      },
      error => {
        this._error.error(error);
      }
    );
  }

  public getUserDialog(): void {
    this.dialogOpen = 'Project user';
    this.addDialog = true;
    this.selectedUser = null;
  }

  public getLeaderDialog(): void {
    this.dialogOpen = 'Project leader';
    this.addDialog = true;
    this.selectedUser = null;
  }

  /**
   * Method that adds user as a potential project leader/user.
   * @param confirmed Flag that marks whether the confirm button has been clicked.
   */
  public addUser(confirmed: boolean): void {
    if (confirmed) {
      this._project
        .insertUserLeader(this.selectedUser.userId, this.dialogOpen)
        .subscribe(
          data => {
            this.dialogOpen === 'Project user'
              ? this._toast.success(
                  'Success',
                  'Successfully added user',
                  true,
                  1500
                )
              : this._toast.success(
                  'Success',
                  'Successfully added leader',
                  true,
                  1500
                );
            this.getPotentialProjectLeaders();
            this.getPotentialProjectUsers();
            this.addDialog = false;
          },
          error => {
            this._error.error(error);
          }
        );
    }
  }

  /**
   * Method that deselects a previously selected user when clicked on cancel button.
   * @param cancelled Flag that marks whether the cancel button has been clicked.
   */
  public removeParameters(cancelled: boolean): void {
    if (cancelled) {
      this.selectedUser = null;
      this.userToDelete = null;
    }
  }

  /** Method that selects user to be deleted from the potential users/leaders table when clicked on edit button.
   * @param user Selected user.
   */
  public getUserToDelete(user): void {
    this.userToDelete = user;
    this.deleteDialog = true;
  }

  public delete(): void {
    this.deleteDialog = true;

    if (this.deleting === 'user') {
      this.deleteProjectUser();
    } else {
      this.deletePorojectLeader();
    }
  }

  /** Delete project user */
  private deleteProjectUser(): void {
    this._project.deletePotentialUser(this.userToDelete.user.userId).subscribe(
      data => {
        if (data) {
          this._toast.success(
            'Success',
            this.userToDelete.username + ' deleted from the table!',
            true,
            2000
          );
          this.getPotentialProjectUsers();
          this.deleteDialog = false;
        } else {
          this._toast.error(
            'Error',
            this.userToDelete.username + ' not deleted.',
            true,
            2000
          );
        }
      },
      error => {
        this._error.error(error);
      }
    );
  }

  /** Delete project leader */
  private deletePorojectLeader(): void {
    this._project.deletePotentialLeader(this.userToDelete.user.userId).subscribe(
      data => {
        if (data) {
          this._toast.success(
            'Success',
            this.userToDelete.username + ' deleted from the table!',
            true,
            2000
          );
          this.getPotentialProjectLeaders();
          this.deleteDialog = false;
        } else {
          this._toast.error(
            'Error',
            this.userToDelete.username + 'not deleted.',
            true,
            2000
          );
        }
      },
      error => {
        this._error.error(error);
      }
    );
  }
}
