export class UserStatisticsByProjectsAndTasks {
  user: {
    userId: number;
    username: string;
    firstName: string;
    lastName: string;
  };
  projects: {
    projectId: number;
    projectName: string;
    projectTasks: {
      taskId: number;
      title: string;
      workingHours: number;
    }[];
    totalProjectWorkingHours: number;
  }[];
  totalHours: number;
}
