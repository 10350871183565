import { ProfileModule } from './../../modules/profile/profile.module';
import { DashboardModule } from './../../modules/dashboard/dashboard.module';
import { LayoutRoutingModule } from './layout-routing.module';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main/main.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { TestComponent } from './test/test.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    LayoutRoutingModule,
    RouterModule,
    FormsModule,
    AngularFontAwesomeModule,
    SharedModule,
    DashboardModule,
    ProfileModule
  ],
  declarations: [MainComponent, HeaderComponent, SidebarComponent, TestComponent]
})
export class LayoutModule { }
