import { ProjectReduced } from './../../calendar/models/project-reduced.model';
export class Message {
  id: number;
  message: string;
  startDate: any;
  expirationDate: any;
  project: ProjectReduced;

  get projectName() {
    return this.project.name ? this.project.name : null;
  }
}
