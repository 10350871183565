import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  Output,
  ElementRef,
  HostListener,
  EventEmitter,
  forwardRef
} from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true
    }
  ]
})
export class DropdownComponent implements OnInit, ControlValueAccessor {
  /** Flag that marks whether the dropdown menu is open or not. */
  public dropdownOpen: boolean = false;

  /** Iterator for the arrow key navigation.  */
  private index: number = 0;

  // tslint:disable-next-line:max-line-length
  /** Flag needed so that, when navigating dropdown with the up and down keys, the i variable doesn't increase (and skips the first or the last item) if the up or down key are pressed for the first time. */
  private firstKeyUpDownFlag: boolean = true;

  /** Label that shows inside the input field. */
  @Input() public label: string;

  /** Dataset for the dropdown menu. */
  @Input() public data: any[];

  /** Label that shows above the input field. */
  @Input() public fixedLabel: string;

  /** When the dataset is an array of objects, this option should be used in order to choose which object attribute will be shown.
   *           If the dataset is not an array of objects, this option must be omitted. */
  @Input() public field: string;

  /** Variable where the selected item is stored.  */
  @Input() public selected: any = '';

  /** Event Emitter for the item selection. */
  @Output() public selectedChange: EventEmitter<any> = new EventEmitter();

  constructor(private _elemref: ElementRef) {}

  /** Method that hides Dropdown menu when clicked somewhere else. */
  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (!this._elemref.nativeElement.contains(event.target)) {
      this.dropdownOpen = false;
    }
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.selected = value;
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  ngOnInit() {}

  /** Method that opens and closes the dropdown menu. */
  public toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  /** Method that selects an item.
   * @param item Item to be selected.
   */
  public select(item: any): void {
    this.selected = item;
    this.propagateChange(this.selected);
    this.selectedChange.emit(this.selected);
  }

  /** Method that moves the dropdown item selection down when the arrow down is pressed.
   * @param event Keyboard event.
   */
  public moveDown(): void {
    if (
      this.index < this.data.length - 1 &&
      this.firstKeyUpDownFlag === false
    ) {
      this.index++;
    }
    if (this.index < this.data.length) {
      this.select(this.data[this.index]);
      this.firstKeyUpDownFlag = false;
    }
  }

  /** Method that moves the dropdown item selection up when the arrow up is pressed.
   * @param event Keyboard event.
   */
  public moveUp(): void {
    if (this.index > 0 && this.firstKeyUpDownFlag === false) {
      this.index--;
    }
    if (this.index >= 0) {
      this.select(this.data[this.index]);
      this.firstKeyUpDownFlag = false;
    }
  }

  /** Method that changes the dropdown input field based on the user selection.
   * @param item Item to be selected.
   */
  public onChange(item: any): void {
    this.select(item);
    this.dropdownOpen = false;
  }
}
