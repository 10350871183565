export class Toast {
  /** Title of the toast message. */
  public title: string;
  /** Text of the toast message. */
  public text: string;
  /** Flag that marks whether the toast automatically closes after specified time. */
  public autoClose: boolean;
  /** Duration of the toast in milliseconds. The toast closes after the specified time has passed (if autoClose is true). */
  public closeTime: number;
  /** Type of the toast: success, error, warning or info. */
  public type: string;
  /**Timer for the toast, used when autoClose is true. */
  public timer;
  /** Marks the percentage of the toast duration. */
  public loaded: number = 0;

  constructor(title: string = '', text: string = '', type: string = '', closeTime: number = 7000, autoClose: boolean = true) {
    this.title = title;
    this.text = text;
    this.autoClose = autoClose;
    this.closeTime = closeTime;
    this.type = type;
  }
}
