import { Component, OnInit, Output, EventEmitter, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-confirm-modal-dialog',
  templateUrl: './confirm-modal-dialog.component.html',
  styleUrls: ['./confirm-modal-dialog.component.scss']
})
export class ConfirmModalDialogComponent implements OnInit {

  /** Flag that marks whether the dialog is open or not. */
  @Input() public dialogOpen: boolean = false;
  /** Title of the confirm dialog. */
  @Input() public dialogTitle: string;
  /** Message of the confirm dialog. */
  @Input() public message: string;
  /** Confirm text of the confirm dialog. */
  @Input() public confirmText: string = 'Confirm';
  /**Cancel text of the confirim dialog. */
  @Input() public cancelText: string = 'Cancel';


  /** Event Emitter for the confirmation. Emits True when clicked on Yes. */
  @Output() public confirm: EventEmitter<boolean> = new EventEmitter();

  /** Event Emitter for the cancellation. Emits True when clicked on No. */
  @Output() public cancel: EventEmitter<boolean> = new EventEmitter();

  constructor(
  ) { }

  ngOnInit() {
  }

  /** Method that handles confirmation. */
  public confirmation(confirmed: boolean): void {
    if (confirmed) {
      this.confirm.emit(true);
    } else {
      this.cancel.emit(true);
      this.closeDialog();
    }
  }

  @HostListener('window:keyup', ['$event'])
  /** Method that handles keyboard events so the dialog can be confirmed by pressing the Enter key and cancelled by pressing the Escape key.
   * @param event Keyboard press.
   */
  keyEvent(event: KeyboardEvent) {
    if (this.dialogOpen) {
      if (event.keyCode === 13) { // enter
        this.confirmation(true);
      }
      if (event.keyCode === 27) { // escape
        this.confirmation(false);
      }
    }
  }

  /** Method that closes the confirm dialog when clicked on "No" or close icon. */
  public closeDialog(): void {
    this.dialogOpen = false;
  }

  /** Method that opens the confirm dialog.
 * @param title Title of the dialog message.
 * @param message Body of the dialog message.
 */
  public openDialog() {
    this.dialogOpen = true;
  }
}
