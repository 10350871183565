export class PotentialUserLeader {
  id: number;
  user: {
    userId: number,
    username: string,
    firstName: string,
    lastName: string
  };
  projectRole: string;

  get userName() {
    return this.user ? this.user.firstName + ' ' + this.user.lastName : null;
  }

  get userUsername() {
    return this.user ? this.user.username : null;
  }

  get userFirstName() {
    return this.user ? this.user.firstName : null;
  }

  get userLastName() {
    return this.user ? this.user.lastName : null;
  }
}
