import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-data-grid-pagination',
  templateUrl: './data-grid-pagination.component.html',
  styleUrls: ['./data-grid-pagination.component.scss']
})
export class DataGridPaginationComponent implements OnInit {

  /** User defined search input */
  @Input() public isSearch: string;
  @Output() public pagedDataChange: EventEmitter<any> = new EventEmitter();

  pager: any = {};

  constructor(

  ) { }

  ngOnInit() {
  }


  /** Method that invokes new pagination page */
  public setPage(page: number): void {
    this.pagedDataChange.emit(page);
  }

}
