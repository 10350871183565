import { TabsComponent } from '../tabs/tabs.component';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {
  /** Flag that marks if the tab is currently active or not. */
  @Input() public active: boolean = false;
  /** Flag that marks if the tab is disabled or not. */
  @Input() public disabled: boolean = false;
  /** Tab title. */
  @Input() public tabTitle: string;

  /** Upon construction, each tab is added to the tabs array in the Tabs component. */
  constructor(
    public tabs: TabsComponent
  ) {
    this.tabs.addTab(this);
  }

  ngOnInit() {
  }

}
