import { ProfileRoutingModule } from './profile.routing';
import { EmployeesModule } from './../employees/employees.module';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './components/profile/profile.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { ProfileInfoComponent } from './components/profile-info/profile-info.component';
import { RouterModule } from '@angular/router';
import { SettingsComponent } from './components/settings/settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileEditUserComponent } from './components/profile-edit-user/profile-edit-user.component';
import { ProfileEditPasswordComponent } from './components/profile-edit-password/profile-edit-password.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DpDatePickerModule,
    EmployeesModule,
    RouterModule,
    ProfileRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    ProfileComponent,
    ProfileInfoComponent,
    SettingsComponent
  ],
  declarations: [ProfileComponent, ProfileInfoComponent, SettingsComponent, ProfileEditUserComponent, ProfileEditPasswordComponent]
})
export class ProfileModule { }
