import { Component, OnInit, Input } from '@angular/core';
import { ToastService } from '../toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  /** Variable that decides whether the toastBox will be displayed at the top or at the bottom. */
  @Input() public placement: string = 'bottom';

  constructor(
    public _toast: ToastService,
  ) {
  }

  ngOnInit() {
  }

}
