import { Router } from '@angular/router';
import { AuthService } from './../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  /** Flag that marks whether the page is loading, for styling purposes. */
  public loading: boolean = false;

  /** Error message to show on login error. */
  public errorMessage: string;

  /** Font awesome icon class ('fa fa-eye' or 'fa fa-eye-slash') */
  public passwordIcon: string = 'fa fa-eye-slash';

  /** Flag that marks whether the password is shown. */
  public passwordShown = false;

  /** Password type: password is pass is not shown, text if pass is shown. */
  public passwordType: string = 'password';


  /** Form group for login: username and password. */
  public loginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl('')
  });


  constructor(
    private _auth: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  /** Method that handles login. */
  public login(): void {
    this.loading = true;
    this._auth.login(this.loginForm.value)
      .subscribe(
        dat => {
          this.errorMessage = null;
          this._auth.auth()
            .subscribe(
              data => {
                localStorage.setItem('em2_user', JSON.stringify(data));
                this.router.navigate(['']);
              },
              error => {
                switch (error.status) {
                  case 0:
                  this.errorMessage = 'Cannot connect to server';
                  break;
                  case 403:
                  this.errorMessage = 'Wrong username and/or password!';
                  break;
                  default:
                  this.errorMessage = 'Unknown error';
                  break;
                }
                this.loading = false;
              }
            );
        },
        error => {
          switch (error.status) {
            case 0:
            this.errorMessage = 'Cannot connect to server';
            break;
            case 403:
            this.errorMessage = 'Wrong username and/or password!';
            break;
            default:
            this.errorMessage = 'Unknown error';
            break;
          }
          this.loading = false;
        }
      );
  }

  public togglePassword(): void {
    this.passwordShown = !this.passwordShown;
    this.passwordShown ? this.passwordIcon = 'fa fa-eye' : this.passwordIcon = 'fa fa-eye-slash';
    this.passwordShown ? this.passwordType = 'text' : this.passwordType = 'password';
  }

}
