import { ProjectReduced } from './../../../calendar/models/project-reduced.model';
import { Message } from './../../../messages/models/message.model';
import { MessagesService } from './../../../messages/services/messages.service';
import { Vacation } from './../../../vacation/models/vacation.model';
import { VacationService } from './../../../vacation/services/vacation.service';
import { AuthService } from './../../../../core/auth/auth.service';
import { ErrorService } from './../../../../core/error.service';
import { EmployeesService } from './../../../employees/services/employees.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  /** Number of hours which the user worked this year. */
  public yearHours: number = 0;

  /** NUmber of hours which the user worked this month. */
  public monthHours: number = 0;

  /** List of approved vacations. */
  public vacations: Vacation[] = [];

  /** Start date for vacations. (today). */
  public start: string;

  /** End date for vacations. */
  public end: string;

  /** Messages for the noticeboard. */
  public messages: Message[] = [];

  /** Four messages to be shown on the noticeboard page. */
  public messagesToBeShown: Message[] = [];

  /** Current message page. */
  public messagePage: number;

  /** Maximum number of pages. */
  public numberOfPages: number;

  /** Today date */
  public today: moment.Moment = moment();

  /** Number of messages per page */
  private messagesPerPage: number = 4;

  @ViewChild('title', { static: true }) private noticeboardTitle: ElementRef;
  @ViewChild('pagination', { static: true })
  private noticeboardPagination: ElementRef;
  @ViewChild('noticeboard', { static: true }) private noticeboard: ElementRef;

  constructor(
    private _employees: EmployeesService,
    private _error: ErrorService,
    public _auth: AuthService,
    private _vacation: VacationService,
    private _messages: MessagesService
  ) {}

  ngOnInit() {
    this.getMonthlyStatistics();
    this.getYearlyStatistics();
    this.getVacationsForCurrentYear();
    this.getMessages();
  }

  /** Method that gets messages from the database */
  public getMessages(): void {
    this.messages = [];
    this._messages.getAllMessages().subscribe(
      data => {
        this.messages = data;
        this.messages.forEach(message => {
          if (!message.project) {
            message.project = new ProjectReduced(null, '');
          }
        });
        this.createNoticeboard();
      },
      error => {
        this._error.error = error;
      }
    );
  }

  /** Method that controls message pagination */
  public createNoticeboard(): void {
    this.messagesToBeShown = [];
    this.messagePage = 0;
    if (
      this.noticeboard &&
      this.noticeboardTitle &&
      this.noticeboardPagination
    ) {
      this.messagesPerPage = Math.floor(
        (this.noticeboard.nativeElement.offsetHeight -
          this.noticeboardTitle.nativeElement.offsetHeight -
          this.noticeboardPagination.nativeElement.offsetHeight -
          30) /
          80
      );
    }
    this.numberOfPages = Math.ceil(this.messages.length / this.messagesPerPage);
    for (let i = 0; i < this.messagesPerPage; i++) {
      if (i < this.messages.length) {
        this.messagesToBeShown.push(this.messages[i]);
      } else {
        break;
      }
    }
  }

  public previousMessages(): void {
    if (this.messagePage > 0) {
      this.messagePage--;
      this.messagesToBeShown = [];
      for (
        let i = this.messagesPerPage * this.messagePage;
        i < this.messagesPerPage * this.messagePage + this.messagesPerPage;
        i++
      ) {
        if (i < this.messages.length) {
          this.messagesToBeShown.push(this.messages[i]);
        } else {
          break;
        }
      }
    }
  }

  public nextMessages(): void {
    if (this.messagePage < this.numberOfPages - 1) {
      this.messagePage++;
      this.messagesToBeShown = [];
      for (
        let i = this.messagesPerPage * this.messagePage;
        i < this.messagesPerPage * this.messagePage + this.messagesPerPage;
        i++
      ) {
        if (i < this.messages.length) {
          this.messagesToBeShown.push(this.messages[i]);
        } else {
          break;
        }
      }
    }
  }

  /** Method that gets statistics for the current month.   */
  public getMonthlyStatistics(): void {
    const start =
      moment()
        .date(1)
        .format('YYYY-MM-DD') + ' 00:00:00';
    const end =
      moment()
        .date(moment().daysInMonth())
        .format('YYYY-MM-DD') + ' 23:59:59';
    this._employees
      .getUserStatisticsByProjects(start, end, this._auth.user.userId)
      .subscribe(
        data => {
          this.monthHours = 0;
          if (data.totalHours) {
            const timer = setInterval(() => {
              if (this.monthHours < 10) {
                if (this.monthHours + 1 <= data.totalHours) {
                  this.monthHours += 1;
                } else {
                  this.monthHours = data.totalHours;
                }
              } else if (this.monthHours < 100) {
                if (this.monthHours + 10 <= data.totalHours) {
                  this.monthHours += 10;
                } else {
                  this.monthHours = data.totalHours;
                }
              } else if (this.monthHours < 1000) {
                if (this.monthHours + 100 <= data.totalHours) {
                  this.monthHours += 100;
                } else {
                  this.monthHours = data.totalHours;
                }
              } else {
                if (this.monthHours + 1000 <= data.totalHours) {
                  this.monthHours += 1000;
                } else {
                  this.monthHours = data.totalHours;
                }
              }
              if (this.monthHours === data.totalHours) {
                clearInterval(timer);
              }
            }, 30);
          }
        },
        error => {
          this._error.error(error);
        }
      );
  }

  /** Method that gets statisticss for the current year. */
  public getYearlyStatistics(): void {
    const start = moment().year() + '-01-01 00:00:00';
    const end = moment().year() + '-12-31 23:59:59';
    this._employees
      .getUserStatisticsByProjects(start, end, this._auth.user.userId)
      .subscribe(
        data => {
          this.yearHours = 0;
          if (data.totalHours) {
            const timer = setInterval(() => {
              if (this.yearHours < 10) {
                if (this.yearHours + 1 <= data.totalHours) {
                  this.yearHours += 1;
                } else {
                  this.yearHours = data.totalHours;
                }
              } else if (this.yearHours < 100) {
                if (this.yearHours + 10 <= data.totalHours) {
                  this.yearHours += 10;
                } else {
                  this.yearHours = data.totalHours;
                }
              } else if (this.yearHours < 1000) {
                if (this.yearHours + 100 <= data.totalHours) {
                  this.yearHours += 100;
                } else {
                  this.yearHours = data.totalHours;
                }
              } else {
                if (this.yearHours + 1000 <= data.totalHours) {
                  this.yearHours += 1000;
                } else {
                  this.yearHours = data.totalHours;
                }
              }
              if (this.yearHours === data.totalHours) {
                clearInterval(timer);
              }
            }, 30);
          }
        },
        error => {
          this._error.error(error);
        }
      );
  }

  /** Method that gets vacations for the currentYear */
  public getVacationsForCurrentYear(): void {
    const start = moment()
      .month(0)
      .date(1)
      .format('YYYY-MM-DD');
    const end = moment()
      .month(11)
      .date(31)
      .format('YYYY-MM-DD');
    this.start = moment(start).format('DD.MM.YYYY.');
    this.end = moment(end).format('DD.MM.YYYY.');
    this._vacation
      .getVacationByUserIdAndTimeScope(start, end, this._auth.user.userId)
      .subscribe(
        data => {
          this.vacations = [];
          data.forEach(vacation => {
            if (vacation.accepted) {
              this.vacations.push(vacation);
            }
          });
        },
        error => {
          this._error.error(error);
        }
      );
  }
}
